import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { Appointment, Assignment } from '../domain/Publisher';
import { ServerStore } from './ServerStore';

export class AppointmentStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    appointments: Appointment[] = [];
    assignments: Assignment[] = [];

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    reset() {
        this.appointments = [];
        this.assignments = [];
    }

    getAll = () => {
        this.serverStore.get('/appointments', action((appointments) => {
            this.appointments = appointments;
        }));

        this.serverStore.get('/assignments', action((assignments) => {
            this.assignments = assignments;
        }));
    }
}