import { observer } from "mobx-react-lite";
import { Divider, IconButton, Toolbar } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useStores } from "../../stores";

export default observer(() => {
    const { panelStore } = useStores();

    const toggleDrawer = () => {
        panelStore.setCollapsed(!panelStore.sideBarCollapsed);
    };

    return (
        <>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
        </>
    )
});
