import * as React from 'react';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Popover, Typography } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useTranslation } from "react-i18next";
import { LatLng } from 'leaflet';
import { Territory } from '../../../domain/Territory';
import TerritoryMap from '../map/TerritoryMap';
import { boundsId } from '../mapUtils';

interface Props {
    territory: Territory;
    isOpen: boolean;
    onClose: () => void;
    onAssign?: (territory: Territory) => void;
}

export default (props: Props) => {
    const { t } = useTranslation();
    const [notesPopover, setNotesPopover] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setNotesPopover(event.currentTarget);
    const handleClose = () => setNotesPopover(null);

    const handleAssign = () => {
        props.onClose();
        props.onAssign && props.onAssign(props.territory!);
    }

    const bounds = JSON.parse(props.territory.boundary) as LatLng[][];
    return (
        <Dialog open={props.isOpen} fullWidth={true} maxWidth="xl" onClose={props.onClose}>
            <Container maxWidth="xl">
                <DialogTitle>
                    {props.territory.title}
                    {(!!props.onAssign && props.territory) && (
                        <IconButton onClick={handleAssign}>
                            <AssignmentIndIcon />
                        </IconButton>)}
                </DialogTitle>
                <DialogContent>
                    <Box style={{ height: "60vh" }}>
                        <TerritoryMap
                            style={{ height: "100%", width: "100%" }}
                            polygons={bounds.map((arr: LatLng[]) => ({
                                id: boundsId(arr, props.territory),
                                bounds: arr,
                                territory: props.territory!
                            }))}
                            currentTerritory={props.territory}
                        />
                    </Box>
                    {props.territory.notes && <Popover
                        open={!!notesPopover}
                        anchorEl={notesPopover}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Typography sx={{ p: 2 }}>{props.territory.notes}</Typography>
                    </Popover>}
                </DialogContent>

                <DialogActions>
                    {props.territory.notes && <Button onClick={handleClick}>{t("notes")}</Button>}
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                </DialogActions>
            </Container>
        </Dialog >
    );
};