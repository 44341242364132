import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useFormik } from 'formik';
import { useStores } from '../../stores';
import { Button, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";

interface Props {
    index: number;
    tab: number;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { congregationInfoStore } = useStores();

    React.useEffect(() => {
        congregationInfoStore.getAll();
    }, []);


    const initialValues = congregationInfoStore.congregationInfo.reduce((obj, cur) => ({ ...obj, [cur.id]: cur.value }), {})
    // const validationSchema = congregationInfoStore.congregationInfo.reduce((obj, cur) => ({ ...obj, [cur.id]: yup.string().required(`${cur.name} is required`) }), {});
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        // validationSchema,
        onSubmit: (values) => {
            const infos = Object.keys(values).map(key => ({ id: key, value: (values as any)[key] }));
            congregationInfoStore.saveInfos(infos);
        },
    });

    return (
        <div
            role="tabpanel"
            hidden={props.tab !== props.index}>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <form onSubmit={formik.handleSubmit}>
                        {congregationInfoStore.congregationInfo.map(info => {
                            return <TextField
                                key={info.id}
                                fullWidth
                                margin="normal"
                                id={String(info.id)}
                                name={String(info.id)}
                                label={t(info.name)}
                                value={(formik.values as any)[info.id] || ''}
                                onChange={formik.handleChange}
                                error={(formik.touched as any)[info.id] && Boolean((formik.errors as any)[info.id])}
                                helperText={(formik.touched as any)[info.id] && (formik.errors as any)[info.id]}
                            />
                        })}
                        <Button color="primary" variant="contained" fullWidth type="submit">
                            {t("submit")}
                        </Button>
                    </form>
                </Paper>
            </Container>
        </div>
    );
});