import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { Publisher } from '../domain/Publisher';
import { FieldServiceGroup } from '../domain/FieldServiceGroup';
import { ServerStore } from './ServerStore';
import moment from 'moment';

export class PublisherStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    myPublisher?: Publisher;
    publishers: Publisher[] = [];
    groups: FieldServiceGroup[] = [];

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    reset() {
        this.publishers = [];
    }

    addPublisher = (publisher: Publisher) => {
        this.publishers.push(publisher);
    }

    getMyPublisher = () => {
        const id = this.rootStore.userStore.userData.publisherId;
        if (id) {
            this.getPublisher(id, (publisher: Publisher) => {
                this.myPublisher = publisher;
            })
        } else {
            console.warn("User without connected publisher");
        }
    }

    findLocalPublisher = (publisherId: number): Publisher | undefined => {
        return this.publishers.find(publ => publ.id === publisherId);
    }

    getPublisher = (id: number, callback: (publisher: Publisher) => void) => {
        this.serverStore.get(`/publishers/${id}`, action((data) => {
            callback(data);
        }));
    }

    getInactivePublishers = (callback?: (data: any) => void) => {
        this.serverStore.get('/publishers?inactive=true', action((data) => {
            callback && callback(data);
        }));
    }

    getDeletedPublishers = (callback?: (data: any) => void) => {
        this.serverStore.get('/publishers?deleted=true', action((data) => {
            callback && callback(data);
        }));
    }

    getAllPublishers = (fetchReports?: boolean, callback?: (data: any) => void) => {
        let url = '/publishers';
        if (fetchReports) {
            url = `${url}?reports=true`;
        }
        this.serverStore.get(url, action((data) => {
            this.publishers = data;
            callback && callback(data);
        }));
    }

    getPublishersWithReports = (date: string, callback?: (data: any) => void) => {
        this.serverStore.get(`/publishers?reports=true&date=${date}`, action((data) => {
            callback && callback(data);
        }));
    }

    getPublishersWithTerritoryAssignments = (callback?: (data: any) => void) => {
        this.serverStore.get('/publishers?territoryAssignments=true', action((data) => {
            callback && callback(data);
        }));
    }

    getPublishersWithTerritoryRequests = (callback?: (data: any) => void) => {
        this.serverStore.get('/publishers?territoryRequests=true', action((data) => {
            callback && callback(data);
        }));
    }

    createPublisher = (newPublisher: Publisher, callback: () => void) => {
        const publisherData = {
            ...newPublisher,
            appointments: newPublisher.appointments.map(app => app.id),
            assignments: newPublisher.assignments.map(app => app.id),
        }
        this.serverStore.post('/publishers', publisherData, action((data) => {
            this.rootStore.notificationsStore.add('publisher_is_created', "success", { 
                name: `${newPublisher.firstName} ${newPublisher.lastName}` 
            });
            callback();
        }), (error) => {
            console.log(error)
        });
    }

    updatePublisher = (id: number, newPublisher: Publisher, callback: () => void) => {
        const publisherData = {
            ...newPublisher,
            appointments: newPublisher.appointments.map(app => app.id),
            assignments: newPublisher.assignments.map(app => app.id),
        }
        this.serverStore.put('/publishers/' + id, publisherData, action(() => {
            this.rootStore.notificationsStore.add('publisher_is_updated', "success", { 
                name: `${publisherData.firstName} ${publisherData.lastName}` 
            });
            callback();
        }));
    }

    deactivatePublisher = (id: number, callback?: () => void) => {
        this.serverStore.delete('/publishers/soft/' + id, action((data) => {
            this.rootStore.notificationsStore.add('publisher_is_deactivated', "success");
            setTimeout(() => {
                this.getAllPublishers();
                callback && callback();
            }, 500);
        }));
    }

    restorePublisher = (id: number, callback?: () => void) => {
        this.serverStore.post('/publishers/restore/' + id, null, action((data) => {
            this.rootStore.notificationsStore.add('publisher_is_restored', "success");
            setTimeout(() => {
                this.getAllPublishers();
                callback && callback();
            }, 500);
        }));
    }

    deletePublisher = (id: number, callback?: () => void) => {
        this.serverStore.delete('/publishers/' + id, action((data) => {
            this.rootStore.notificationsStore.add('publisher_is_deleted', "success");
            setTimeout(() => {
                this.getAllPublishers();
                callback && callback();
            }, 500);
        }));
    }

    getServiceGroup = (id: number, date: Date, callback: (group: any) => void) => {
        this.serverStore.get(`/field-service-group/${id}?date=${moment(date).format("YYYY-MM")}`, action((data) => {
            callback(data);
        }))
    }

    getAllServiceGroups = () => {
        this.serverStore.get('/field-service-group', action((data) => {
            this.groups = data;
        }), (error) => console.log(error))
    }

    createServiceGroup = (name: string, overseer: number) => {
        this.serverStore.post('/field-service-group/', { name, overseer }, action((data) => {
            this.rootStore.notificationsStore.add('service_group_is_created', "success");
            this.getAllServiceGroups();
        }), (error) => console.log(error));
    }

    updateServiceGroup = (id: number, values: any) => {
        this.serverStore.put('/field-service-group/' + id, values, action((data) => {
            this.rootStore.notificationsStore.add('service_group_is_updated', "success");
            this.getAllServiceGroups();
        }), (error) => console.log(error));
    }

    deleteServiceGroup = (id: number) => {
        this.serverStore.delete('/field-service-group/' + id, action((data) => {
            this.rootStore.notificationsStore.add('service_group_is_deleted', "success");
            this.getAllServiceGroups();
        }), (error) => console.log(error));
    }

    uploadPublishers = (file: any[], success?: () => void) => {
        this.serverStore.upload('/publishers/upload', file[0], () => {
            this.rootStore.notificationsStore.add('publishers_are_imported', "success");
            success && success();
        });
    }

    downloadGroupExcel = (groupId: number, date: Date) => {
        this.serverStore.download('/field-service-group/excel', { date, groupId });
    }

    exportPublishersExcel = () => {
        this.serverStore.download('/publishers/export');
    }

    publisherById = (id: number): Publisher => {
        return this.publishers.find(publ => publ.id === id)!;
    }
}