import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { CongregationEvent } from '../domain/CongregationEvent';
import { ServerStore } from './ServerStore';

export class CongregationEventsStore{
    rootStore: RootStore;
    serverStore: ServerStore;
    events: CongregationEvent[] = [];

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    getAll = () => {
        this.serverStore.get('/congregation-event', action((data) => {
            this.events = data;
        }));
    }

    createEvent = (data: any) => {
        this.serverStore.post('/congregation-event', data, action(() => {
            this.getAll();
        }));
    }

    updateEvent = (id: number, data: any) => {
        this.serverStore.patch(`/congregation-event/${id}`, data, action(() => {
            this.getAll();
        }));
    }

    deleteEvent = (id: number) => {
        this.serverStore.delete(`/congregation-event/${id}`, action(() => {
            this.getAll();
        }));
    }
}