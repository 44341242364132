import * as React from 'react';
import Panel from '../panel/Panel';
import { Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import PivotTableTab from './PivotTableTab';
import { ServiceAssignment } from '../../domain/Publisher';

export default observer(() => {
    const { t } = useTranslation();
    const [tab, setTab] = React.useState(0);

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <Panel
            title={t("pivot_reports")}
            headerItems={[]}
        >
            <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label={t("publishers")} />
                <Tab label={t("pioneers")} />
                <Tab label={t("auxiliary_pioneers")} />
                <Tab label={t("special_pioneers")} />
                <Tab label={t("all")} />
            </Tabs>
            <div role="tabpanel" hidden={tab !== 0}>
                <PivotTableTab serviceAssignment="Publishers" />
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <PivotTableTab serviceAssignment={ServiceAssignment.RegularPioneer} />
            </div>
            <div role="tabpanel" hidden={tab !== 2}>
                <PivotTableTab serviceAssignment={ServiceAssignment.AuxiliaryPioneer} />
            </div>
            <div role="tabpanel" hidden={tab !== 3}>
                <PivotTableTab serviceAssignment={ServiceAssignment.SpecialPioneer} />
            </div>
            <div role="tabpanel" hidden={tab !== 4}>
                <PivotTableTab serviceAssignment="All" />
            </div>
        </Panel >
    );
});