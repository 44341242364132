import { IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { FieldServiceReport } from '../../domain/FieldServiceReport';
import { getGridLocale } from '../../utils/localeUtils';
import { isMobileOnly } from 'react-device-detect';

interface Props {
    reports: FieldServiceReport[];
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();

    const visibleColumns = () => {
        let columns = {
            minutes: false
        } as any;

        if (isMobileOnly) {
            columns = {
                ...columns,
                bibleStudies: false,
                serviceAssignment: false,
                notes: false
            }
        }
        return columns;
    }

    return (
        <DataGrid
                localeText={getGridLocale().components.MuiDataGrid.defaultProps.localeText}
                rowHeight={30}
                initialState={{
                    sorting: { sortModel: [{ field: 'date', sort: 'desc' }] },
                    columns: {
                        columnVisibilityModel: visibleColumns(),
                    },
                }}
                rows={
                    props.reports.map((report: FieldServiceReport) => ({
                        id: report.id,
                        date: moment(report.date).format("YYYY-MM"),
                        hours: report.hours,
                        bibleStudies: report.bibleStudies,
                        serviceAssignment: report.serviceAssignment && t(report.serviceAssignment),
                        notes: report.notes
                    }))
                }
                columns={[
                    { field: 'date', headerName: t('date'), flex: 1 },
                    { field: 'hours', headerName: t('hours'), flex: 1 },
                    { field: 'bibleStudies', headerName: t('bible_studies'), flex: 1 },
                    { field: 'serviceAssignment', headerName: t('service_assignment'), flex: 1 },
                    { field: 'notes', headerName: t('notes'), flex: 1 },
                    {
                        field: 'actions', headerName: t('actions'), flex: 1,
                        renderCell: (cellValues) => (<>
                            <IconButton onClick={() => props.onEdit(+cellValues.id)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => props.onDelete(+cellValues.id!)}>
                                <DeleteIcon color="error" />
                            </IconButton>
                        </>)
                    },
                ]}
                components={{ Toolbar: GridToolbar }}
            />
    );
});