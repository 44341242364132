import * as React from 'react';
import Container from '@mui/material/Container';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import S4ReportsComponent from '../../components/s4reports/S4ReportsComponent';
import { useTranslation } from "react-i18next";
import { Button } from '@mui/material';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import FieldServiceReportDialog from '../publishers/dialogs/FieldServiceReportDialog';
import { FieldServiceReport } from '../../domain/FieldServiceReport';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

export default observer(() => {
    const { userStore, publisherStore, reportStore } = useStores();
    const { t } = useTranslation();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const user = userStore.userData;
    const hasPublisher = !!user.publisherId;

    if (hasPublisher) {
        React.useEffect(() => {
            reportStore.shouldPublisherSubmitReport(user.publisherId!, moment().subtract(1, 'month').format("YYYY-MM-DD"));
        }, [reportStore.serviceReports[user.publisherId!]]);
    }

    const handleReportSubmit = (report: FieldServiceReport) => {
        reportStore.createS4Report(report, () => {
            closeDialog();
        });
    }

    const headerItems = [];
    if (hasPublisher) {
        if (!reportStore.submitted) {
            headerItems.push(
                <Button key="submit_a_report" color="inherit" onClick={() => openDialog("submit-report")}>
                    {t("submit_a_report")}
                </Button>
            );
        } else {
            headerItems.push(
                <Button key="submit_a_report" color="inherit">
                    {t("your_report_is_submitted")}
                </Button>
            );
        }

    }

    return (
        <Panel title={t("my_reports")} headerItems={headerItems}>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                {hasPublisher ?
                    <S4ReportsComponent publisherId={userStore.userData.publisherId} /> :
                    <div>{t("publisher_is_not_linked_to_this_user")}</div>}
            </Container>
            <FieldServiceReportDialog
                isOpen={isOpen("submit-report")}
                publisher={publisherStore.myPublisher!}
                onSubmit={handleReportSubmit}
                onClose={closeDialog} />
        </Panel>
    );
})