import MuiDrawer from '@mui/material/Drawer';
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { isMobileOnly } from 'react-device-detect';
import { PropsWithChildren } from '../props';

interface Props {}

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }: any) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default observer((props: PropsWithChildren<Props>) => {
    const { panelStore } = useStores();

    const hidden = isMobileOnly ? panelStore.sideBarCollapsed : false;
    return (
        <Drawer variant="permanent" hidden={hidden} open={!panelStore.sideBarCollapsed} >
            {props.children}
        </Drawer >
    )
})
