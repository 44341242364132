import { TableCell, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";

interface Props {
    name: string;
}

export default (props: Props) => {
    const { t } = useTranslation();

    const color = () => {
        if (props.name === "treasures") return "#575a5d";
        if (props.name === "apply-yourself") return "#be8900";
        if (props.name === "living-as-christians") return "#7e0024";
        if (props.name === "assignment") return "#6faade";
        return "#000";
    }

    return (
        <TableRow>
            <TableCell colSpan={4} style={{ color: "white", backgroundColor: color(), fontSize: "1.3em" }}>{t(props.name)}</TableCell>
        </TableRow>
    );
};