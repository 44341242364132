import * as React from 'react';
import Container from '@mui/material/Container';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';
import { AccountingSummary } from '../../../domain/AccountingSummary';
import moment from 'moment';
import AccountingSummaryTable from '../AccountingSummaryTable';
import YearSwitcher from '../../../components/YearSwitcher';

export default observer(() => {
    const { accountingStore } = useStores();
    const [summary, setSummary] = React.useState<AccountingSummary>();
    const [date, setDate] = React.useState<Date>(new Date());

    React.useEffect(() => {
        accountingStore.getSummary(moment(date).format("YYYY"), "year", (summary) => {
            setSummary(summary);
        });
    }, [date]);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <YearSwitcher date={date} onDateUpdate={(date) => setDate(date)} />
            <AccountingSummaryTable summary={summary} />
        </Container>
    );
});