import * as React from 'react';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react-lite';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import 'moment/locale/ru';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FieldServiceReport } from '../../domain/FieldServiceReport';
import { useTranslation } from "react-i18next";
import { getAcademicYearRange, sum } from '../../utils/dateUtils';
import "./S4ReportsTable.css";
import { reportColor } from '../../utils/reportColorUtils';
import { isMobileOnly } from 'react-device-detect';

interface Props {
    reports: FieldServiceReport[];
    editable?: boolean;
    toolbarItems?: React.ReactElement[];
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const moment = extendMoment(Moment);


    const rowProps = (report: FieldServiceReport): React.CSSProperties => {
        return { backgroundColor: reportColor(report) }
    }

    const getAllYears = (): string[] => {
        const dates: moment.Moment[] = props.reports.map(report => moment(report.date)).sort((a, b) => b.diff(a));
        dates.unshift(moment(dates[0]).add(1, 'year'));
        return Array.from(new Set(dates.map(date => date.format("YYYY"))));
    }

    const years = getAllYears();

    const ReportRow = (args: { report: FieldServiceReport }) => {
        const report = args.report;
        return (
            <TableRow style={rowProps(report)} className='report-table-row' key={report.id}>
                {/* <TableCell>{moment(report.date).format("YYYY-MM")}</TableCell> */}
                <TableCell><i>{moment(report.date).format("MMMM")}</i></TableCell>
                <TableCell><b>{report.empty ? t("no") : t("yes")}</b></TableCell>
                <TableCell><b>{report.hours || ''}</b></TableCell>
                <TableCell>{report.bibleStudies || ''}</TableCell>
                {!isMobileOnly && <TableCell>{report.serviceAssignment && t(report.serviceAssignment)}</TableCell>}
                {!isMobileOnly && <TableCell>{report.notes}</TableCell>}
                {props.editable && (
                    <TableCell>
                        <IconButton size='small' onClick={() => props.onEdit(report.id!)}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton size='small' onClick={() => props.onDelete(report.id!)}>
                            <DeleteIcon fontSize="small" color="error" />
                        </IconButton>
                    </TableCell>
                )}
            </TableRow>
        );
    }

    const SummaryReportRow = (args: { report: FieldServiceReport, text: string }) => {
        const report = args.report;
        return (
            <TableRow style={{ backgroundColor: "#d219801a" }} className='report-table-row' key={report.id}>
                <TableCell><b>{t(args.text)}</b></TableCell>
                <TableCell></TableCell>
                <TableCell><b>{report.hours || ''}</b></TableCell>
                <TableCell><b>{report.bibleStudies || ''}</b></TableCell>
                {!isMobileOnly && <TableCell></TableCell>}
                {!isMobileOnly && <TableCell></TableCell>}
                {props.editable && (
                    <TableCell></TableCell>
                )}
            </TableRow>
        );
    }

    const YearRow = (args: { year: ReturnType<any> }) => {
        const { year } = args;
        const range: any = getAcademicYearRange(year).map(date => moment(date));
        const yearReports = props.reports.filter(report => moment.range(range).contains(moment(report.date))).reverse();

        if (yearReports.length) {
            const averageReport = {
                id: "avg",
                hours: Math.ceil(sum(yearReports, "hours") / yearReports.length),
                bibleStudies: (sum(yearReports, "bibleStudies") / yearReports.length).toFixed(1),
            } as any;
            const overallReport = {
                id: "overall",
                hours: sum(yearReports, "hours"),
                bibleStudies: sum(yearReports, "bibleStudies"),
            } as any;

            return (
                <>
                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell component="th" scope="row">
                            <h2>{year}</h2>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Table className="report-table" size="small" >
                                <TableHead>
                                    <TableRow >
                                        {/* <TableCell></TableCell> */}
                                        <TableCell></TableCell>
                                        <TableCell>{t('was_preaching')}</TableCell>
                                        <TableCell>{t('hours')}</TableCell>
                                        <TableCell>{t('bible_studies')}</TableCell>
                                        {!isMobileOnly && <TableCell>{t('service_assignment')}</TableCell>}
                                        {!isMobileOnly && <TableCell>{t('notes')}</TableCell>}
                                        {props.editable && (
                                            <TableCell>{t('actions')}</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {yearReports.map((report: FieldServiceReport) => (
                                        <ReportRow
                                            key={report.id}
                                            report={report}
                                        />
                                    ))}
                                    <SummaryReportRow
                                        report={averageReport}
                                        text="average"
                                    />
                                    <SummaryReportRow
                                        report={overallReport}
                                        text="overall"
                                    />
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                </>
            );
        } else {
            return <TableRow />;
        }
    }

    return (
        <TableContainer >
            {props.toolbarItems}
            <Table>
                <TableBody>
                    {years.map(year => <YearRow key={year} year={year} />)}
                </TableBody>
            </Table>
        </TableContainer>
    );
});