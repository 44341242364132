import * as React from 'react';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";
import { getLocale } from '../../../utils/localeUtils';
import { useStores } from '../../../stores';
import * as yup from 'yup';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    date: Date;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { accountingStore } = useStores();

    const schema = yup.object().shape({
        transferToBranchDate: yup.date(),
        confirmNumber1: yup.number().required(t("field_is_required", { name: t("from_box") })),
        confirmNumber2: yup.number().required(t("field_is_required", { name: t("from_box") })),
        monthlyResolution: yup.number().required(t("field_is_required", { name: t("monthly_resolution") })),
        exceedResolution: yup.number().required(t("field_is_required", { name: t("monthly_resolution") }))
    });

    const initialValues = {
        transferToBranchDate: moment().toDate(),
        confirmNumber1: 0,
        confirmNumber2: 0,
        monthlyResolution: 0,
        exceedResolution: 0
    }

    const formik = useFormik({
        validationSchema: schema,
        initialValues,
        onSubmit: (values) => {
            accountingStore.downloadTO62({ date: props.date, ...values });
            props.onClose();
        },

    });

    React.useEffect(() => {
        if (props.isOpen) {
            accountingStore.getSummary(moment(props.date).format("YYYY-MM"), "month", (summary) => {
                formik.setFieldValue("monthlyResolution", summary.expenses["Ежемесячное пожертвование по рез."])
            });
        }
    }, [props.isOpen]);

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("download_to_62")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("transfer_to_branch_date")}
                            maxDate={new Date()}
                            value={formik.values.transferToBranchDate}
                            onChange={value => {
                                formik.setFieldValue('transferToBranchDate', value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" name="date" />}
                        />
                    </LocalizationProvider>

                    <Grid container>
                        <Grid item padding={1}>
                            <TextField
                                margin="normal"
                                id="monthlyResolution"
                                label={t("monthly_resolution")}
                                type="number"
                                variant="standard"
                                value={formik.values.monthlyResolution}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item padding={1}>
                            <TextField
                                margin="normal"
                                id="exceedResolution"
                                label={t("exceed_resolution")}
                                type="number"
                                variant="standard"
                                value={formik.values.exceedResolution}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item padding={1}>
                            <TextField
                                margin="normal"
                                id="confirmNumber1"
                                label={t("enter_confirmation_to_branch_accountant") + " 1"}
                                type="number"
                                variant="standard"
                                value={formik.values.confirmNumber1}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item padding={1}>
                            <TextField
                                margin="normal"
                                id="confirmNumber2"
                                label={t("enter_confirmation_to_branch_accountant") + " 2"}
                                type="number"
                                variant="standard"
                                value={formik.values.confirmNumber2}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});