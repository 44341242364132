import { Button, Toolbar } from '@mui/material';
import Can from '../../components/Can';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import AccountingS26Dialog from './dialogs/AccountingS26Dialog';
import AccountingTO62Dialog from './dialogs/AccountingTO62Dialog';
import AccountingS30Dialog from './dialogs/AccountingS30Dialog';

interface Props {
    date: Date;
}

export default (props: Props) => {
    const { isOpen, openDialog, closeDialog } = useOpenDialog();

    return (
        <Toolbar sx={{ pr: '24px' }}>
            <Can do="manage" on='Accounting'>
                <Button type="button" color="error" onClick={() => { openDialog("s26-dialog") }} >
                    S-26
                </Button>
            </Can>
            <Can do="manage" on='Accounting'>
                <Button type="button" color="secondary" onClick={() => { openDialog("s30-dialog") }} >
                    S-30
                </Button>
            </Can>
            <Can do="manage" on='Accounting'>
                <Button type="button" onClick={() => { openDialog("to62-dialog") }} >
                    TO-62
                </Button>
            </Can>
            <AccountingS26Dialog
                date={props.date}
                isOpen={isOpen("s26-dialog")}
                onClose={closeDialog} />
            <AccountingS30Dialog
                date={props.date}
                isOpen={isOpen("s30-dialog")}
                onClose={closeDialog} />
            <AccountingTO62Dialog
                date={props.date}
                isOpen={isOpen("to62-dialog")}
                onClose={closeDialog} />
        </Toolbar>
    );
};