import { useStores } from '../../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { Territory } from '../../../domain/Territory';
import { isMobile } from 'react-device-detect';

interface Props {
    territory: Territory;
    isOpen: boolean;
    onClose: () => void;
    onTerritoryReturned?: () => void;
}


export default observer((props: Props) => {
    const { t } = useTranslation();
    const { territoryStore } = useStores();

    const handleReturnUnworked = () => {
        territoryStore.returnTerritory(props.territory.id, true, () => {
            props.onTerritoryReturned && props.onTerritoryReturned();
        });
        props.onClose();
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: () => {
            territoryStore.returnTerritory(props.territory.id, false, () => {
                props.onTerritoryReturned && props.onTerritoryReturned();
            });
            props.onClose();
        },
    });

    return (
        <Dialog open={props.isOpen} fullWidth={true} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{t("do_you_want_to_return_the_territory")}</DialogTitle>
                <DialogContent>
                    <div><b>{`${t("territory")}: `}</b>{props.territory.title}</div>
                    {props.territory.territoryGroup && <div><b>{`${t("group")}: `}</b>{props.territory.territoryGroup.name}</div>}
                    {!isMobile && <div><b>{`${t("notes")}: `}</b>{props.territory.notes}</div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button size='small' color="error" onClick={handleReturnUnworked}>{t("return_unworked")}</Button>
                    <Button color="primary" type="submit">{t("return_back")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});