import * as React from 'react';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, TextareaAutosize, Autocomplete, FormControl } from '@mui/material';
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import PublisherTextField from '../schedule-table/PublisherTextField';
import { Assignment } from '../../../domain/Publisher';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface Props {
    isOpen: boolean;
    entry?: ScheduleEntry;
    assignments: Assignment[];
    onSubmit: (entry: ScheduleEntry) => void;
    onDelete: (entry: ScheduleEntry) => void;
    onClose: () => void;
}

export default (props: Props) => {
    const { t } = useTranslation();
    const [speakerFromCongregation, setSpeakerFromCongregation] = React.useState<boolean>(props.entry ? !props.entry.customSpeaker : true);
    const [isImpromptu, setIsImpromptu] = React.useState<boolean>(props.entry ? !!props.entry.impromptuId : false);
    const schema = yup.object().shape({
        name: yup.string().required(t("field_is_required", { name: t("name") })),
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: props.entry || {} as ScheduleEntry,
        onSubmit: (entry) => {
            props.onSubmit(entry);
            props.onClose();
        }
    });

    const handleDelete = () => {
        props.onDelete(props.entry!);
        props.onClose();
    }

    const { values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("schedule_entry")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={8} padding={1}>
                            <TextField
                                margin="normal"
                                id="name"
                                label={t("name")}
                                fullWidth
                                variant="standard"
                                value={values.name || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>

                        <Grid item xs={2} padding={1}>
                            <TextField
                                margin="normal"
                                id="position"
                                label={t("position")}
                                type="number"
                                fullWidth
                                variant="standard"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                value={values.position || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={2} padding={1}>
                            <TextField
                                margin="normal"
                                id="room"
                                label={t("room")}
                                type="number"
                                fullWidth
                                variant="standard"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                value={values.room || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6} padding={1}>
                            <Autocomplete
                                size='small'
                                disablePortal
                                id="participants"
                                options={[
                                    "elders",
                                    "appointed",
                                    "brothers",
                                    "sisters",
                                    "all"
                                ]}
                                getOptionLabel={(option: string) => t(option)}
                                value={values.participants}
                                renderInput={(params) => <TextField {...params} margin="normal" label={t("participants")} />}
                                onChange={(e, option) => {
                                    formik.setFieldValue("participants", option);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} padding={1}>
                            <Autocomplete
                                size='small'
                                disablePortal
                                id="assignment"
                                options={props.assignments.map(assign => assign.id)}
                                getOptionLabel={(option: number) => t(props.assignments.find(assign => assign.id === option)!.name)}
                                value={values.assignmentId}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) => <TextField {...params} margin="normal" label={t("assignment")} />}
                                onChange={(e, option) => {
                                    const assignment = props.assignments.find(assign => assign.id === option)
                                    formik.setFieldValue("assignmentId", assignment?.id)
                                    formik.setFieldValue("assignment", assignment)
                                }}
                            />
                        </Grid>
                    </Grid>

                    <FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={speakerFromCongregation}
                                    onChange={(event) => setSpeakerFromCongregation(event.target.checked)}
                                />
                            }
                            label={t("speaker_from_a_congregation")}
                        />
                    </FormControl>
                    {speakerFromCongregation ? (
                        <PublisherTextField
                            entry={props.entry!}
                            field="speaker"
                            editable={true}
                            assigned={[]}
                            onEntryUpdate={(entry) => {
                                formik.setFieldValue("speakerId", entry.speaker?.id);
                                formik.setFieldValue("speaker", entry.speaker);
                            }}
                        />
                    ) : (
                        <TextField
                            margin="normal"
                            id="customSpeaker"
                            label={t("speaker")}
                            fullWidth
                            variant="standard"
                            value={values.customSpeaker || ''}
                            onChange={formik.handleChange}
                        />
                    )}

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.companionNeeded}
                                    onChange={(event) => formik.setFieldValue("companionNeeded", event.target.checked)}
                                />
                            }
                            label={t("companion")}
                        />
                    </FormGroup>

                    <div style={{ display: values.companionNeeded ? undefined : "none" }}>
                        <PublisherTextField
                            entry={props.entry!}
                            field="companion"
                            editable={true}
                            assigned={[]}
                            onEntryUpdate={(entry) => {
                                formik.setFieldValue("companion", entry.companion);
                                formik.setFieldValue("companionId", entry.companion?.id);
                            }}
                        />
                    </div>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isImpromptu}
                                    onChange={(event) => setIsImpromptu(event.target.checked)}
                                />
                            }
                            label={t("impromptu")}
                        />
                    </FormGroup>
                    <div style={{ display: isImpromptu ? undefined : "none" }}>
                        <PublisherTextField
                            entry={props.entry!}
                            field="impromptu"
                            editable={true}
                            assigned={[]}
                            onEntryUpdate={(entry) => {
                                formik.setFieldValue("impromptuId", entry.impromptuId);
                                formik.setFieldValue("impromptu", entry.impromptu);
                            }}
                        />
                    </div>

                    <br />
                    <TextareaAutosize
                        id="notes"
                        value={values.notes || ''}
                        minRows={6}
                        placeholder={t("notes")}
                        style={{ width: "100%" }}
                        onChange={formik.handleChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleDelete}>{t("delete")}</Button>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};