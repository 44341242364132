import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { CartWitnessingPlace } from '../../domain/CartWitnessingPlace';
import * as yup from 'yup';

interface Props {
    isOpen: boolean;
    cartPlace?: CartWitnessingPlace;
    onSubmit: (cartPlace: CartWitnessingPlace) => void;
    onClose: () => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        name: yup.string().required(t("field_is_required", { name: t("name") })),
        address: yup.string().required(t("field_is_required", { name: t("address") })),
        notes: yup.string().nullable()
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: props.cartPlace || {
            name: "",
        } as CartWitnessingPlace,
        onSubmit: (cartPlace) => {
            props.onSubmit(cartPlace);
        }
    });

    const { errors, values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("cart_witnessing_place")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>

                    <TextField
                        autoFocus
                        margin="normal"
                        id="name"
                        label={t("name")}
                        fullWidth
                        variant="standard"
                        error={!!errors.name}
                        helperText={errors.name && errors.name as string}
                        value={values.name || ''}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        margin="normal"
                        id="address"
                        label={t("address")}
                        fullWidth
                        variant="standard"
                        error={!!errors.address}
                        helperText={errors.address && errors.address as string}
                        value={values.address || ''}
                        onChange={formik.handleChange}
                    />

                    <TextField
                        margin="normal"
                        id="notes"
                        label={t("notes")}
                        type="text"
                        fullWidth
                        variant="standard"
                        error={!!errors.notes}
                        helperText={errors.notes && errors.notes as string}
                        value={values.notes || ''}
                        onChange={formik.handleChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});