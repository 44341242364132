import { ScheduleEntry } from "../../../domain/ScheduleEntry";
import { compact } from 'lodash';

export const prepareAssigned = (entries: ScheduleEntry[]): number[] => {
    if (entries) {
        let assigned = entries.map(entry => entry.speakerId);
        assigned = assigned.concat(entries.map(entry => entry.companionId))
        return Array.from(new Set(compact(assigned)));
    }
    return [];
}