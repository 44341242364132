import * as React from 'react';
import { useStores } from '../../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { Territory } from '../../../domain/Territory';

interface Props {
    territory: Territory;
    isOpen: boolean;
    onTerritoryRequest: () => void;
    onClose: () => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { territoryStore, publisherStore, notificationsStore } = useStores();
    const [isAssigned, setIsAssigned] = React.useState<boolean>(false);

    React.useEffect(() => {
        territoryStore.isTerritoryAssigned(props.territory.id, (result) => {
            setIsAssigned(result)
        });
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { notes: '' },
        onSubmit: (values, { resetForm }) => {
            territoryStore.requestTerritory(props.territory.id, values.notes, props.onTerritoryRequest);
            resetForm();
            props.onClose();
        },
    });

    if (!publisherStore.myPublisher) {
        notificationsStore.add(t("you_are_not_able_to_request_territories"), "error");
        return <span />
    }

    const { values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                    {t("request_territory")}
                </DialogTitle>
                <DialogContent>
                    {t("request_territory_text", { title: props.territory.title })}

                    <TextField
                        margin="normal"
                        fullWidth
                        disabled={true}
                        type="text"
                        variant="standard"
                        value={`${publisherStore.myPublisher.lastName} ${publisherStore.myPublisher.firstName}`}
                        placeholder={t("notes")}
                    />

                    <TextField
                        margin="normal"
                        id="notes"
                        fullWidth
                        type="text"
                        variant="standard"
                        disabled={isAssigned}
                        value={values.notes}
                        placeholder={t("notes")}
                        onChange={formik.handleChange}
                    />

                </DialogContent>
                <DialogActions>
                    {isAssigned ?
                        <Button disabled={true}>{t("assigned_already")}</Button> :
                        <>
                            <Button onClick={props.onClose}>{t("cancel")}</Button>
                            <Button disabled={isAssigned} type="submit">{t("request")}</Button>
                        </>}

                </DialogActions>
            </form>
        </Dialog>
    );
});