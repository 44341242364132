import { IconButton, TableCell, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";
import Can from '../../../components/Can';
import { AccountingEntry } from '../../../domain/AccountingEntry';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from 'material-ui-confirm';
import { border, format } from './accountingTableUtils';

interface Props {
    entry: AccountingEntry;
    onEntryEdit: (entry: AccountingEntry) => void;
    onEntryDelete: (entry: AccountingEntry) => void;
}

export default (props: Props) => {
    const { t } = useTranslation();
    const confirm = useConfirm();

    const handleDelete = (deletedEntry: AccountingEntry) => {
        confirm({
            title: t("are_you_sure"),
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            props.onEntryDelete(deletedEntry);
        });
    }

    const getAmount = (entry: AccountingEntry, side: string, type: string) => {
        if (entry.side === "transfer" &&
            ((entry.type === type && side === "expenses") ||
                (entry.transferDirection === type && side === "income"))) {
            return format(entry.amount);
        }
        if ((entry.type === type || type === "total") && entry.side === side) {
            return format(entry.amount);
        } else {
            return "";
        }
    }

    const color = (entry: AccountingEntry) => {
        if (entry.side === "transfer") {
            return "#e0fa191A";
        } else if (entry.code === "EDI" || entry.code === "DI") {
            return "#fa19190D";
        } else if (entry.code === "RM" || entry.code === "R" || entry.code === "PMN") {
            return "#2419fa0D";
        } else if (entry.code === "ZE") {
            return "#1dfa190A";
        }
    }
    const entry = props.entry;
    return (
        <TableRow style={{ backgroundColor: color(entry) }}>
            <TableCell align="center">{moment(entry.date).format('DD.MM.YYYY')}</TableCell>
            <TableCell align="center">{entry.document}</TableCell>
            <TableCell align="center">{entry.counterparty}</TableCell>
            <TableCell sx={border} align="center">{entry.description}</TableCell>
            <TableCell sx={border} align="center"><i>{entry.code}</i></TableCell>
            <TableCell sx={border} align="center">{getAmount(entry, "income", "cash")}</TableCell>
            <TableCell sx={border} align="center">{getAmount(entry, "expenses", "cash")}</TableCell>
            <TableCell sx={border} align="center">{getAmount(entry, "income", "bank")}</TableCell>
            <TableCell sx={border} align="center">{getAmount(entry, "expenses", "bank")}</TableCell>
            <TableCell sx={border} align="center">{getAmount(entry, "income", "total")}</TableCell>
            <TableCell sx={border} align="center">{getAmount(entry, "expenses", "total")}</TableCell>
            <Can do="manage" on='Accounting'>
                <TableCell sx={border} align="center">
                    <IconButton size='small' onClick={() => props.onEntryEdit(props.entry)}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton size='small' onClick={() => handleDelete(entry)}>
                        <DeleteIcon fontSize="small" color="error" />
                    </IconButton>
                </TableCell>
            </Can>
        </TableRow>
    );
};