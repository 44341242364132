import * as React from 'react';
import { Collapse, IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useTranslation } from "react-i18next";
import { FieldServiceGroup } from '../../../domain/FieldServiceGroup';
import Can from '../../../components/Can';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/system';
import { Publisher } from '../../../domain/Publisher';

interface Props {
    group: FieldServiceGroup;
    publishers: Publisher[];
    onGroupEdit: (group: FieldServiceGroup) => void;
    onPublisherEdit: (publisher: Publisher) => void;
    onGoToReports: (group: FieldServiceGroup) => void;
}

export default (props: Props) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)} >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{props.group.name}</TableCell>
                <TableCell>{props.group.overseer.lastName}</TableCell>
                <Can do="update" on='Groups'>
                    <TableCell>
                        <IconButton onClick={() => props.onGroupEdit(props.group)}>
                            <EditIcon />
                        </IconButton>
                        <Can do="read" on='OverallReport'>
                            <IconButton onClick={() => props.onGoToReports(props.group)} >
                                <SummarizeIcon />
                            </IconButton>
                        </Can>
                    </TableCell>
                </Can>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                {t("publishers")}
                            </Typography>
                            <Table size="small">
                                <TableBody>
                                    {props.publishers.map((publisher, i) => (
                                        <TableRow key={publisher.id} onClick={() => props.onPublisherEdit(publisher)}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>
                                                {`${publisher.lastName} ${publisher.firstName}`}
                                            </TableCell>
                                            <TableCell>{publisher.inactive && t("inactive")}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};