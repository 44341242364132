import * as React from 'react';
import { FeatureGroup, MapContainer, Polygon, TileLayer, Tooltip } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Territory } from '../../../domain/Territory';
import L, { LatLngExpression, LatLng } from 'leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import { MapViewChange } from '../map/MapViewChange';
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "react-leaflet-fullscreen/styles.css";
import "./TerritoryMap.css"


interface Props {
    polygons: {
        id: string,
        title?: string,
        color?: string,
        bounds: LatLng[],
        territory: Territory,
    }[];
    style: React.CSSProperties;
    tooltipOnHover?: boolean;
    scrollWheelZoom?: boolean;
    currentTerritory?: Territory;
    editing?: boolean;
    onPolygonClick?: (terrotory: Territory) => void;
    onPolygonCreate?: (bounds: LatLng[]) => void;
    onPolygonEdit?: (bounds: LatLng[][]) => void;
    onPolygonDelete?: (bounds: LatLng[][]) => void;
}

const defaultCenter = process.env.REACT_APP_TERRITORIES_DEFAULT_LOCATION!.split(',').map(Number) as LatLngExpression;

export default (props: Props) => {
    const featureGroupRef = React.useRef<L.FeatureGroup<any> | null>(null);
    const [focusedShape, setFocusedShape] = React.useState<LatLngExpression[]>([]);

    React.useEffect(() => {
        if (props.currentTerritory) {
            if (props.currentTerritory.boundary.length > 0) {
                const arr = JSON.parse(props.currentTerritory.boundary) as { lat: number, lng: number}[][];
                if (arr.length) {
                    let shape = arr[0] as LatLngExpression[];
                    if (arr.length > 1) {
                        shape = arr.map(polygon => L.latLng(polygon[0].lat, polygon[1].lng));
                    }
                    setFocusedShape(shape);
                }
            }
        }
    }, [props.currentTerritory]);

    const editShapes = (e: any) => {
        if (featureGroupRef.current) {
            const allPolygons: any[] = [];
            featureGroupRef.current.eachLayer(layer => {
                if (layer instanceof L.Polygon) {
                    allPolygons.push(...layer.getLatLngs());
                }
            });
            props.onPolygonEdit && props.onPolygonEdit(allPolygons);
        }
    }

    const handleFeatureGroupReady = (ref: L.FeatureGroup<any> | null) => {
        if (ref != null && !featureGroupRef.current) {
            const geoJson = {
                type: 'FeatureCollection',
                features: props.polygons.map(po => ({
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'Polygon',
                        coordinates: [po.bounds.map((b: LatLng) => [b.lng, b.lat])],
                    }
                }))
            } as any;

            let leafletGeoJSON = new L.GeoJSON(geoJson)
            leafletGeoJSON.eachLayer((layer) => {
                ref.addLayer(layer);
            });

            featureGroupRef.current = ref;
        }
    }

    return (
        <MapContainer
            style={props.style}
            center={defaultCenter}
            zoom={13}
            scrollWheelZoom={!!props.scrollWheelZoom}>

            <MapViewChange shape={focusedShape} />
            {props.editing && (
                <FeatureGroup ref={(ref) => handleFeatureGroupReady(ref)}>

                    <EditControl
                        position='topright'
                        onCreated={editShapes}
                        onEdited={editShapes}
                        onDeleted={editShapes}
                        draw={{
                            rectangle: false,
                            polyline: false,
                            circle: false,
                            circlemarker: false,
                            marker: false
                        }} />

                </FeatureGroup>
            )}

            {!props.editing && props.polygons.map(polygon => (
                <Polygon
                    key={polygon.id}
                    color={polygon.color || '#1976d2'}
                    positions={polygon.bounds}
                    eventHandlers={{ click: () => props.onPolygonClick && props.onPolygonClick(polygon.territory) }}
                >
                    {!!props.tooltipOnHover && <Tooltip sticky>{polygon.title}</Tooltip>}
                </Polygon>
            ))}

            <FullscreenControl />

            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
        </MapContainer>
    );
};