import * as React from 'react';
import { useFormik } from 'formik';
import { useStores } from '../stores';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";
import { Publisher } from '../domain/Publisher';
import { getLocale } from '../utils/localeUtils';

interface Props {
    isOpen: boolean;
    publisherId?: number;
    onClose: () => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { reportStore, publisherStore } = useStores();

    React.useEffect(() => {
        if (!publisherStore.publishers.length) {
            publisherStore.getAllPublishers();
        } 
    }, [publisherStore.publishers.length]);

    let publisher: Publisher;
    if (publisherStore.publishers) {
        const foundPublisher = publisherStore.publishers.find(publ => publ.id === props.publisherId);
        if (foundPublisher) {
            publisher = foundPublisher;
        } else {
            publisher = publisherStore.publishers[0];
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { 
            year1: moment().format('YYYY-MM-DD'),
            year2: moment().subtract(1, 'year').format('YYYY-MM-DD'),
            publisher: publisher!
    } ,
        onSubmit: (values) => {
            reportStore.downloadS21Report({
                year1: values.year1,
                year2: values.year2,
                publisherId: values.publisher.id
            });
        }
    });

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("download_s_21")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("year")}
                            views={['year']}
                            value={formik.values.year1}
                            maxDate={new Date()}
                            onChange={value => {
                                formik.setFieldValue('year1', value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" name="from" />}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("year")}
                            views={['year']}
                            value={formik.values.year2}
                            maxDate={new Date()}
                            onChange={value => {
                                formik.setFieldValue('year2', value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" name="from" />}
                        />
                    </LocalizationProvider>
                    <Autocomplete
                            disablePortal
                            id="publisher"
                            options={publisherStore.publishers}
                            getOptionLabel={(publisher: Publisher) => publisher.firstName ? `${publisher.firstName} ${publisher.lastName}` : ''}
                            value={formik.values.publisher}
                            renderInput={(params) => <TextField {...params} margin="normal" label={t("publisher")} />}
                            onChange={(e, publisher) => {
                                formik.setFieldValue("publisher", publisher)
                            }}
                        />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});