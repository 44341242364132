import * as React from 'react';
import { Collapse, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SquareIcon from '@mui/icons-material/Square';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TerritoryGroup } from '../../../domain/TerritoryGroup';
import Can from '../../../components/Can';
import { useTranslation } from 'react-i18next';
import { PropsWithChildren } from '../../props';

interface Props {
    group: TerritoryGroup;
    editable?: boolean;
    onGroupEdit?: (group: TerritoryGroup) => void;
    onGroupHide?: (group: TerritoryGroup) => void;
}

export default (props: PropsWithChildren<Props>) => {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();

    const handleGroupHide = () => {
        const group = { ...props.group, hidden: !props.group.hidden };
        props.onGroupHide && props.onGroupHide(group)
    }

    const handleGroupEdit = () => {
        props.onGroupEdit && props.onGroupEdit(props.group)
    }

    const territories = props.group.territories || [];
    return (
        <>
            <TableRow>
                <TableCell padding='none'>
                    <IconButton size="small" onClick={() => setOpen(!open)} >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <SquareIcon style={{ color: props.group.color }} />
                        <Tooltip title={t("number_of_territories", { number: territories.length })} placement="right">
                            <Typography variant="body1">{props.group.name}</Typography>
                        </Tooltip>
                    </Stack>
                </TableCell>
                {props.editable && <Can do="update" on='TerritoryGroup'>
                    <TableCell>
                        <IconButton onClick={handleGroupEdit}>
                            <EditIcon />
                        </IconButton>
                        <Can do="update" on='TerritoryGroup'>
                            <IconButton onClick={handleGroupHide} >
                                {props.group.hidden ? <VisibilityOffIcon /> : <VisibilityIcon color="primary" />}
                            </IconButton>
                        </Can>
                    </TableCell>
                </Can>}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {props.children}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};