import * as React from 'react';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Button, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import AttendanceDialog from '../../components/AttendanceDialog';
import GenerateS88Dialog from '../../components/GenerateS88Dialog';
import Can from '../../components/Can';
import { useNavigate } from 'react-router-dom';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import { Attendance } from '../../domain/Attendance';
import AttendanceChartTab from './AttendanceChartTab';
import AttendanceTableTab from './AttendanceTableTab';
import { isMobile } from 'react-device-detect';

export default observer(() => {
    const { t } = useTranslation();
    const { attendanceStore, reportStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [selectedAttendance, setSelectedAttendance] = React.useState<Attendance | undefined>();
    const navigate = useNavigate();
    const [tab, setTab] = React.useState(0);

    React.useEffect(() => {
        attendanceStore.getWorkdays();
        attendanceStore.getWeekends();
    }, []);

    const openPivotAttendance = () => {
        navigate('/pivot-attendance');
    }

    const handleEditAttendance = (attendance: Attendance) => {
        setSelectedAttendance(attendance);
        openDialog("attendance-dialog");
    }

    const handleDeleteAttendance = (id: number) => {
        attendanceStore.deleteAttendance(id);
    }

    const handleCloseDialog = () => {
        setSelectedAttendance(undefined);
        closeDialog();
    }

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const headerItems = () => {
        const items = [];
        if (!isMobile) {
            items.push(
                <Can key="download_s_88" do="create" on='S88Report'>
                    <Button
                        type="button"
                        color="inherit"
                        onClick={() => { openDialog("s88-dialog") }}>
                        {t("download_s_88")}
                    </Button>
                </Can>
            );

            items.push(
                <Can key="open_pivot_attendance" do="read" on='S88Report'>
                    <Button
                        type="button"
                        color="inherit"
                        onClick={openPivotAttendance}>
                        {t("pivot_reports")}
                    </Button>
                </Can>
            );
        }

        items.push(
            <Can key="add_attendance_btn" do="create" on='Attendance'>
                <Button
                    type="button"
                    color="inherit"
                    onClick={() => { openDialog("attendance-dialog") }}>
                    {t("add_attendance")}
                </Button>
            </Can>
        )

        return items;
    }

    return (
        <Panel
            title={t("attendance")}
            headerItems={headerItems()}>

            <Tabs value={tab} onChange={handleTabChange}>
                <Tab label={t("graph")} />
                <Tab label={t("table")} />
            </Tabs>
            <div role="tabpanel" hidden={tab !== 0}>
                <AttendanceChartTab
                    onEdit={handleEditAttendance}
                />
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <AttendanceTableTab
                    onEdit={handleEditAttendance}
                    onDelete={handleDeleteAttendance}
                />
            </div>

            <AttendanceDialog
                attendance={selectedAttendance}
                isOpen={isOpen("attendance-dialog")}
                onClose={handleCloseDialog} />
            <GenerateS88Dialog
                isOpen={isOpen("s88-dialog")}
                onClose={handleCloseDialog} />
        </Panel >
    );
});