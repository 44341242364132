import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { Role, User } from '../domain/User';
import { ServerStore } from './ServerStore';

export class UserStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    users: User[] = [];
    roles: Role[] = [];
    userData: User = {
        id: -1,
        username: '',
        roles: [],
        loggedIn: false,
    };

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        const savedUser = localStorage.getItem('userData');
        if (savedUser) {
            this.userData = JSON.parse(savedUser);
        }
        makeAutoObservable(this);
    }

    setUsername(username: string) {
        this.userData.username = username;
    }

    reset() {
        this.serverStore.get('/logout', () => {
            console.log(`User ${this.userData.username} is logged out`);
        });
        this.userData.id = -1;
        this.userData.username = '';
        this.userData.roles = [];
        this.userData.loggedIn = false;
        this.rootStore.publisherStore.myPublisher = undefined;
        localStorage.removeItem('userData');
    }

    login(username: string, password: string, callback: (result: boolean, message?: string) => void) {
        this.serverStore.post('/login', { username, password }, action((data) => {
            this.userData = data;
            this.userData.loggedIn = true;
            localStorage.setItem('userData', JSON.stringify(this.userData));
            console.log(`User ${this.userData.username} is logged in`);
            this.rootStore.notificationsStore.add('user_logged_in', "success", { name: username });
            this.rootStore.prepareData();
            callback(true);
        }), action((error) => {
            this.reset();
            callback(false, "Wrong username/password");
        }));
    }

    createUser(userData: any, callback: (result: boolean) => void) {
        this.serverStore.post('/register', userData, action((data) => {
            this.rootStore.notificationsStore.add('new_user_created', "success", { name: userData.username });
            callback(true);
            this.getAllUsers();
        }), (error) => {
            this.rootStore.notificationsStore.add('new_user_creation_failed', "error");
        });
    }

    updateUser(userData: any, callback?: (result: boolean) => void) {
        this.serverStore.put('/users/' + userData.id,
            userData,
            action((data) => {
                callback && callback(true);
                this.rootStore.notificationsStore.add('user_is_updated', "success", { name: userData.username });
                this.getAllUsers();
            }));
    }

    deleteUser(user: User, callback?: (result: boolean) => void) {
        this.serverStore.delete('/users/' + user.id,
            action((data) => {
                this.rootStore.notificationsStore.add('user_is_deleted', "success", { name: user.username });
                callback && callback(true);
                this.getAllUsers();
            }));
    }

    changePassword(password: string, callback: (result: boolean) => void) {
        this.serverStore.patch('/password', { password }, () => {
            this.rootStore.notificationsStore.add('password_changed_successfully', "success");
            callback(true);
        }, () => {
            this.rootStore.notificationsStore.add('failed_to_change_password', "error");
        });
    }

    logout() {
        this.reset();
    }

    getAllRoles() {
        this.serverStore.get('/users/roles', action((data) => {
            this.roles = data;
        }));
    }

    getAllUsers() {
        this.serverStore.get('/users', action((data) => {
            this.users = data;
        }));
    }
}