import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';
import { Attendance } from '../domain/Attendance';

export class AttendanceStore{
    rootStore: RootStore;
    serverStore: ServerStore;
    attendance: Attendance[] = [];
    workdays: Attendance[] = [];
    weekends: Attendance[] = [];

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    getWorkdays = () => {
        this.serverStore.get('/attendance?workdays=true', action((data) => {
            this.workdays = data;
        }));
    }

    getWeekends = () => {
        this.serverStore.get('/attendance?weekends=true', action((data) => {
            this.weekends = data;
        }));
    }

    getAverage = (date: string, callback: (value: number) => void) => {
        this.serverStore.get(`/attendance/avg?date=${date}`, action((value) => {
            callback(value);
        }));
    }

    getAll = () => {
        this.serverStore.get('/attendance', action((data) => {
            this.attendance = data;
        }));
    }

    saveAttendance = (values: any) => {
        this.serverStore.post('/attendance', values, () => {});
    }

    updateAttendance = (id: number, values: any) => {
        this.serverStore.patch('/attendance/' + id, values, () => {
            this.getWorkdays();
            this.getWeekends();
        });
    }

    deleteAttendance = (id: number) => {
        this.serverStore.delete('/attendance/' + id, () => {
            this.getWorkdays();
            this.getWeekends();
        });
    }
}