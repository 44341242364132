import { useStores } from '../../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { LiteratureType } from '../../../domain/LiteratureType';

interface Props {
    isOpen: boolean;
    type?: LiteratureType;
    onClose: () => void;
}

interface FormValues {
    id: number;
    name: string;
    description: string;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { literatureStore } = useStores();

    const initialValues = {} as FormValues;
    if (props.type) {
        initialValues.id = props.type.id;
        initialValues.name = props.type.name;
        initialValues.description = props.type.description;
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: (values) => {
            literatureStore.updateLiteratureType(values.id, {
                id: values.id,
                name: values.name,
                description: values.description
            });
            props.onClose();
        },
    });

    return (
        <Dialog open={props.isOpen} fullWidth={true} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{t("edit_literature")}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="name"
                        label={t("name")}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.name || ''}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        autoFocus
                        margin="normal"
                        id="description"
                        label={t("description")}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.description || ''}
                        onChange={formik.handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button color="primary" type="submit">{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});