import * as React from 'react';
import { useStores } from '../../../stores';
import { Table, TableBody, TableContainer } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import { AccountingEntry } from '../../../domain/AccountingEntry';
import moment from 'moment';
import AccountingEntryDialog from '../dialogs/AccountingEntryDialog';
import { AccountingStartingPoint } from '../../../domain/AccountingStartingPoint';
import AccountingTableFooter from './AccountingTableFooter';
import AccountingTableHeader from './AccountingTableHeader';
import AccountingTableSumRow from './AccountingTableSumRow';
import AccountingTableRow from './AccountingTableRow';

interface Props {
    date: Date;
    onEmptyTable: (empty: boolean) => void;
    onDateChange: (newDate: Date) => void;
}

export default observer((props: Props) => {
    const { accountingStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [entries, setEntries] = React.useState<AccountingEntry[]>([]);
    const [startingPoint, setStartingPoint] = React.useState<AccountingStartingPoint>({ cash: 0, bank: 0, other: 0 });
    const [selectedEntry, setSelectedEntry] = React.useState<AccountingEntry | undefined>(undefined);


    React.useEffect(() => {
        accountingStore.getAll(() => {
            if (!accountingStore.entries.length) {
                props.onEmptyTable(true);
            } else {
                filterEntries(props.date);
            }
        });
        getStartingPoint(props.date);
    }, []);

    React.useEffect(() => {
        filterEntries(props.date);
    }, [accountingStore.entries]);

    const filterEntries = (date: Date) => {
        const currentEntries: AccountingEntry[] = accountingStore.entries.filter(entry => {
            const curDate = moment(date).format("YYYY-MM");
            const entryDate = moment(entry.date).format("YYYY-MM");
            if (curDate === entryDate) {
                return entry;
            }
        });
        setEntries(currentEntries)
    };

    const getStartingPoint = (date: Date) => {
        accountingStore.getStartingPoint(moment(date).format("YYYY-MM"), (startingPoint: AccountingStartingPoint) => {
            setStartingPoint(startingPoint);
        });
    }

    const handleDateUpdate = (date: Date) => {
        props.onDateChange(date);
        filterEntries(date);
        getStartingPoint(date);
    }

    const openEntryDialog = (entry: AccountingEntry) => {
        setSelectedEntry(entry);
        openDialog("entry-dialog")
    }

    const handleCloseDialog = () => {
        setSelectedEntry(undefined);
        closeDialog();
    }

    const handleUpdate = (entry: AccountingEntry) => {
        accountingStore.updateEntry(entry.id!, entry);
    }

    const handleDelete = (entry: AccountingEntry) => {
        accountingStore.deleteEntry(entry.id!);
    }

    return (
        <TableContainer>
            <Table size="small">
                <AccountingTableHeader
                    date={props.date}
                    entries={accountingStore.entries}
                    startingPoint={startingPoint}
                    onDateChange={handleDateUpdate}
                />
                <TableBody>
                    {entries.map((entry: AccountingEntry) => (
                        <AccountingTableRow
                            key={entry.id}
                            entry={entry}
                            onEntryEdit={openEntryDialog}
                            onEntryDelete={handleDelete}
                        />
                    ))}
                    <AccountingTableSumRow entries={entries} />
                </TableBody>
                <AccountingTableFooter
                    date={props.date}
                    entries={entries}
                    startingPoint={startingPoint}
                />
            </Table>

            <AccountingEntryDialog
                entry={selectedEntry}
                date={props.date}
                isOpen={isOpen("entry-dialog")}
                onSubmit={handleUpdate}
                onClose={handleCloseDialog} />
        </TableContainer>
    );
});