import { FieldServiceGroup } from "./FieldServiceGroup";
import { FieldServiceReport } from "./FieldServiceReport";
import { TerritoryAssignment } from "./TerritoryAssignment";
import { TerritoryRequest } from "./TerritoryRequest";

export interface Publisher {
    id: number;
    firstName: string;
    lastName: string;
    anointed: boolean;
    inactive: boolean;
    gender: string;
    address: string;
    phoneNumber: string;
    birthDate: string;
    baptisedAt: string;
    startedAt?: string;
    deletedAt?: string;
    notes: string;
    serviceGroup: FieldServiceGroup;
    appointments: Appointment[];
    serviceAssignment?: ServiceAssignment,
    assignments: Assignment[];
    serviceReports?: FieldServiceReport[];
    territoryAssignments?: TerritoryAssignment[];
    territoryRequests?: TerritoryRequest[];
}

export interface Appointment {
    id: number;
    name: string;
}

export interface Assignment {
    id: number;
    name: string;
    category: string;
}

export enum ServiceAssignment {
    RegularPioneer = "Regular Pioneer",
    AuxiliaryPioneer = "Auxiliary Pioneer",
    SpecialPioneer = "Special Pioneer",
    Missionary = "Missionary"
}