import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';
import { ScheduleWeek } from '../domain/ScheduleWeek';
import { ScheduleEntry } from '../domain/ScheduleEntry';
import ScheduleAnalysis from '../domain/ScheduleAnalysis';
import moment from 'moment';

export class ScheduleStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    weeks: { [key:string]: ScheduleWeek } = {};
    currentWeekDate: Date;
    currentWeek?: ScheduleWeek;

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        this.currentWeekDate = new Date();
        makeAutoObservable(this);
    }

    getWeek = (date: Date, refresh = false) => {
        this.currentWeekDate = date;
        const dateStr = moment(this.currentWeekDate).format("YYYY-MM-DD");
        if (refresh) {
            this.serverStore.get(`/schedule?date=${dateStr}`, action((week) => {
                this.weeks[dateStr] = week;
                this.currentWeek = week;
            }));
        } else {
            const week = this.weeks[dateStr];
            if (!week) {
                this.serverStore.get(`/schedule?date=${dateStr}`, action((week) => {
                    this.weeks[dateStr] = week;
                    this.currentWeek = week;
                }));
            } else {
                this.currentWeek = week;
            }
        }
    }

    getHistory = (assignmentId: number, date: string, callback: (history: ScheduleEntry[]) => void) => {
        this.serverStore.get(`/schedule/history?assignmentId=${assignmentId}&date=${date}`, action((history) => {
            callback(history);
        }));
    }

    getSpeechEntries = (callback: (entries: ScheduleEntry[]) => void) => {
        this.serverStore.get("/schedule/entry?speech=true", action((entries) => {
            callback(entries);
        }));
    }

    getEntriesByPublisher = (publisherId: number, callback: (entries: ScheduleEntry[]) => void) => {
        this.serverStore.get(`/schedule/entry?publisherId=${publisherId}`, action((entries) => {
            callback(entries);
        }));
    }

    uploadEpub = (file: any, roomCount: number, callback?: () => void) => {
        this.serverStore.upload(`/schedule/epub?roomCount=${roomCount}`, file[0], action(() => {
            this.rootStore.notificationsStore.add('epub_is_uploaded', "success");
            this.getWeek(this.currentWeekDate, true);
            callback && callback();
        }));
    }

    downloadExcel = (data: any) => {
        this.serverStore.download('/schedule/excel', data);
    }

    createEntry = (entry: ScheduleEntry) => {
        this.serverStore.post("/schedule/entry", entry, action(() => {
            this.rootStore.notificationsStore.add('schedule_entry_is_created', "success");
            this.getWeek(this.currentWeekDate, true);
        }))
    }

    updateEntry = (entry: ScheduleEntry) => {
        this.serverStore.patch(`/schedule/entry/${entry.id}`, entry, action(() => {
            this.rootStore.notificationsStore.add('schedule_entry_is_updated', "success");
            this.getWeek(this.currentWeekDate, true);
        }))
    }

    updateWeek = (week: ScheduleWeek) => {
        this.serverStore.patch(`/schedule/${week.id}`, week, action(() => {
            this.rootStore.notificationsStore.add('schedule_week_is_updated', "success");
            this.getWeek(this.currentWeekDate, true);
        }))
    }

    deleteEntry = (entry: ScheduleEntry) => {
        this.serverStore.delete(`/schedule/entry/${entry.id}`, action(() => {
            this.rootStore.notificationsStore.add('schedule_entry_is_deleted', "success");
            this.getWeek(this.currentWeekDate, true);
        }))
    }

    generateWeek = (id: number, type: string, callback?: () => void) => {
        this.serverStore.post(`/schedule/generate/`, { id, type }, action(() => {
            this.rootStore.notificationsStore.add('schedule_entries_were_generated_successfully', "success");
            setTimeout(() => this.getWeek(this.currentWeekDate, true), 1000);
            callback && callback();
        }), action(() => {
            callback && callback();
        }))
    }

    getAnalysis = (from: Date | null, to: Date | null, sort: string, callback: (analysis: ScheduleAnalysis) => void) => {
        this.serverStore.post('/schedule/analysis', { from, to, sort }, action((analysis) => {
            callback(analysis);
        }));
    }
}