import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { CongregationInfo } from '../domain/CongregationInfo';
import { ServerStore } from './ServerStore';

export class CongregationInfoStore{
    rootStore: RootStore;
    serverStore: ServerStore;
    congregationInfo: CongregationInfo[] = [];

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    getAll = () => {
        this.serverStore.get('/congregation-info', action((data) => {
            this.congregationInfo = data;
        }));
    }

    saveInfos = (values: any) => {
        this.serverStore.patch('/congregation-info', values, action(() => {
            this.getAll();
        }));
    }
}