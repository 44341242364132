import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle,  Grid, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";
import { AccountingStartingPoint } from '../../../domain/AccountingStartingPoint';
import { getLocale } from '../../../utils/localeUtils';

interface Props {
    isOpen: boolean;
    onSubmit: (startingPoint: AccountingStartingPoint) => void;
    onClose: () => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {date: moment().utcOffset(0, true).toDate(), cash: 0, bank: 0, other: 0},
        onSubmit: props.onSubmit
    });

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("accounting_entry")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("date")}
                            maxDate={new Date()}
                            value={formik.values.date}
                            onChange={value => {
                                formik.setFieldValue('date', value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" name="date" />}
                        />
                    </LocalizationProvider>
                    
                    <Grid container>
                        <Grid item xs={4} padding={1}>
                            <TextField
                                margin="normal"
                                id="cash"
                                label={t("cash")}
                                type="number"
                                variant="standard"
                                value={formik.values.cash}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={4} padding={1}>
                        <TextField
                                margin="normal"
                                id="bank"
                                label={t("bank")}
                                type="number"
                                variant="standard"
                                value={formik.values.bank}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={4} padding={1}>
                        <TextField
                                margin="normal"
                                id="other"
                                label={t("other")}
                                type="number"
                                variant="standard"
                                value={formik.values.other}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});