import DashboardIcon from '@mui/icons-material/Dashboard';
import { useTranslation } from "react-i18next";
import SideBarMenuItem from '../SideBarMenuItem';

export default () => {
    const { t } = useTranslation();
    return (
        <SideBarMenuItem id="side_menu_item_home" to='/home' text={t("home")}>
            <DashboardIcon />
        </SideBarMenuItem>
    )
}
