import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import Can from '../../../components/Can';
import { useTranslation } from "react-i18next";
import SideBarSubMenuItem from '../SideBarSubMenuItem';
import SideBarMenuCollapseItem from '../SideBarMenuCollapseItem';

export default () => {
    const { t } = useTranslation();
    return (
        <SideBarMenuCollapseItem icon={<MapsHomeWorkIcon />} type="territories" text={t("territories")}>
            <SideBarSubMenuItem
                id="side_menu_item_my_territory"
                to="/my-territory"
                text={t("my_territories")} />
            {/* <SideBarSubMenuItem
                id="side_menu_item_territory_request"
                to="/territory-requests"
                text={t("request")} /> */}
            <Can do="manage" on='Territory'>
                <SideBarSubMenuItem
                    id="side_menu_item_territory"
                    to="/territory"
                    text={t("territories")} />
            </Can>
            <Can do="manage" on='Territory'>
                <SideBarSubMenuItem
                    id="side_menu_item_territory_assignments"
                    to="/territory-assignments"
                    text={t("appointments")} />
            </Can>
        </SideBarMenuCollapseItem>
    )
}