import { observer } from 'mobx-react-lite';
import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import PublisherScheduleDialog from '../../publishers/dialogs/PublisherScheduleDialog';

interface Props {
    assignmentId?: number;
    entry?: ScheduleEntry;
    isOpen: boolean;
    onClose: () => void;
}

export default observer((props: Props) => {
    return (
        <PublisherScheduleDialog 
            isOpen={props.isOpen}
            publisherId={props.entry && props.entry.speakerId}
            onClose={props.onClose}
        />
    );
});