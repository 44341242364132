import * as React from 'react';
import { Breakpoint, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Territory } from '../../../domain/Territory';
import MyTerritoriesTableRow from './MyTerritoriesTableRow';
import { getShapeCenter, stringToLatLng } from '../mapUtils';
import ReturnTerritoryDialog from '../dialogs/ReturnTerritoryDialog';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import { TerritoryAssignment } from '../../../domain/TerritoryAssignment';
import { useConfirm } from 'material-ui-confirm';

interface Props {
    size: Breakpoint;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { territoryStore, publisherStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [myAssignments, setMyAssignments] = React.useState<TerritoryAssignment[]>([]);
    const [selectedTerritory, setSelectedTerritory] = React.useState<Territory | undefined>();
    const confirm = useConfirm();

    React.useEffect(() => {
        territoryStore.getMyTerritories((data) => {
            setMyAssignments(data)
        });
    }, [publisherStore.myPublisher]);


    const handleTerritoryDirections = (territory: Territory) => {
        const shape = stringToLatLng(territory.boundary);
        const center = getShapeCenter(shape);
        // navigate(`http://maps.google.com/?ll=${center.lat},${center.lng};`)
        // window.location.href = `http://maps.google.com/?ll=${center.lat},${center.lng};`
        // window.location.href = `https://www.google.com/maps/place/${center.lat},${center.lng};`
        // window.location.href = `https://www.google.com/maps/search/?api=1&query=${center.lat},${center.lng};`
        if (isMobileOnly) {
            confirm({
                title: t("show_route"),
                description: `${t("do_you_have_google_maps_app")}`,
                confirmationText: t("yes"),
                cancellationText: t("no")
            }).then(() => {
                window.open(`maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${center.lat},${center.lng}`);
            });
        } else {
            window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${center.lat},${center.lng}`);
        }
    }

    const openReturnTerritoryDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("return-territory")
    }

    const handleCloseDialog = () => {
        closeDialog();
        setSelectedTerritory(undefined);
    }

    const handleTerritoryReturned = () => {
        territoryStore.getMyTerritories((data) => {
            setMyAssignments(data)
        });
    }

    return (
        <>
            <Container maxWidth={props.size} sx={{ mt: 4, mb: 4 }}>
                <Paper style={{ height: "80vh" }} sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                    {myAssignments.length ? <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>{t("title")}</TableCell>
                                    {!isMobile && <TableCell>{t("group")}</TableCell>}
                                    {!isMobile && <TableCell>{t("days_gone")}</TableCell>}
                                    <TableCell>{t("actions")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {myAssignments
                                    .map((assignment: TerritoryAssignment) => (
                                        <MyTerritoriesTableRow
                                            key={assignment.id}
                                            assignment={assignment}
                                            onTerritoryDirections={handleTerritoryDirections}
                                            onTerritoryReturn={(territory) => openReturnTerritoryDialog(territory)}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer> : (
                        <Typography variant="h6" textAlign={'center'}>
                            {t("you_dont_have_assigned_territories")}
                        </Typography>
                    )}
                </Paper>
            </Container>
            {isOpen("return-territory") && <ReturnTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("return-territory")}
                onClose={handleCloseDialog}
                onTerritoryReturned={handleTerritoryReturned}
            />}
        </>
    );
});