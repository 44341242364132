import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { AccountingAnalysis } from '../../../domain/AccountingAnalysis';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface Props {
    analysis?: AccountingAnalysis[];
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const [incomeChartData, setIncomeChartData] = useState<any[]>([]);
    const [incomeChartType, setIncomeChartType] = useState<string>("congr");
    const [expensesChartData, setExpensesChartData] = useState<any[]>([]);
    const [expensesChartType, setExpensesChartType] = useState<string>("hall");

    const prepareChartData = (ct: string, analysis?: AccountingAnalysis[]) => {
        let newChartData: any[] = [];
        if (analysis) {
            newChartData = analysis.map(row => {
                return {
                    name: row.date,
                    amount: Number((row as any)[ct])
                }
            });
        }
        return newChartData;
    }

    React.useEffect(() => {
        let newChartData = prepareChartData(incomeChartType, props.analysis);
        setIncomeChartData(newChartData);
    }, [props.analysis, incomeChartType]);

    React.useEffect(() => {
        let newChartData = prepareChartData(expensesChartType, props.analysis);
        setExpensesChartData(newChartData);
    }, [props.analysis, expensesChartType]);

    const handleIncomeTypeChange = (_: React.MouseEvent<HTMLElement>, type: any) => {
        setIncomeChartType(type);
    };

    const handleExpensesTypeChange = (_: React.MouseEvent<HTMLElement>, type: any) => {
        setExpensesChartType(type);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 500 }}>
                <h2>{t("total")}</h2>

                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={prepareChartData("total", props.analysis)}
                        margin={{ top: 5, bottom: 5, }}
                    >
                        <XAxis dataKey="name" interval={4} />
                        <YAxis type="number" />
                        <Tooltip formatter={(value: number) => value.toFixed(2)}/>
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        <Line type="monotone" dataKey="amount" name={t("amount")} stroke="#8884d8"  />
                    </LineChart >
                </ResponsiveContainer>
            </Paper>
            <br />

            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 500 }}>
                <h2>{t("income")}</h2>

                <ToggleButtonGroup
                    color="primary"
                    value={incomeChartType}
                    exclusive
                    onChange={handleIncomeTypeChange}
                >
                    <ToggleButton value="congr">{t("congregation_expenses")}</ToggleButton>
                    <ToggleButton value="ww">{t("worldwide")}</ToggleButton>
                    <ToggleButton value="income">{t("total")}</ToggleButton>
                </ToggleButtonGroup>
                <br />

                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={incomeChartData}
                        margin={{ top: 5, bottom: 5, }}
                    >
                        <XAxis dataKey="name" interval={4} />
                        <YAxis type="number" />
                        <Tooltip formatter={(value: number) => value.toFixed(2)}/>
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        <Line type="monotone" dataKey="amount" name={t("amount")} stroke="#8884d8"  />
                    </LineChart >
                </ResponsiveContainer>
            </Paper>
            <br />

            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 500 }}>
                <h2>{t("expenses")}</h2>

                <ToggleButtonGroup
                    color="primary"
                    value={expensesChartType}
                    exclusive
                    onChange={handleExpensesTypeChange}
                >
                    <ToggleButton value="hall">{t("kingdom_hall")}</ToggleButton>
                    <ToggleButton value="resolution">{t("resolution")}</ToggleButton>
                    <ToggleButton value="otherExpenses">{t("other")}</ToggleButton>
                    <ToggleButton value="expenses">{t("expenses")}</ToggleButton>
                </ToggleButtonGroup>
                <br />

                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={expensesChartData}
                        margin={{ top: 5, bottom: 5, }}
                    >
                        <XAxis dataKey="name" interval={4} />
                        <YAxis type="number" />
                        <Tooltip formatter={(value: number) => value.toFixed(2)}/>
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        <Line type="monotone" dataKey="amount" name={t("amount")} stroke="#8884d8"  />
                    </LineChart >
                </ResponsiveContainer>

            </Paper>

        </Container>
    );
});