import * as React from 'react';
import { Container, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import { TerritoryGroup } from '../../../domain/TerritoryGroup';
import TerritoryGroupTableRow from './TerritoryGroupTableRow';
import { Territory } from '../../../domain/Territory';
import AssignTerritoryDialog from '../dialogs/AssignTerritoryDialog';
import ShowTerritoryDialog from '../dialogs/ShowTerritoryDialog';
import TerritoriesTable from './TerritoriesTable';


export default observer(() => {
    const { territoryStore, publisherStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [selectedTerritory, setSelectedTerritory] = React.useState<Territory | undefined>();

    React.useEffect(() => {
        if (territoryStore.groups.length === 0) {
            territoryStore.getAllGroups();
        }
        if (publisherStore.publishers.length === 0) {
            publisherStore.getAllPublishers();
        }
    }, []);


    const handleCloseDialog = () => {
        closeDialog();
        setSelectedTerritory(undefined);
    }

    const openAssignTerritoryDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("assign-territory")
    }

    const showTerritoryDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("show-territory")
    }

    return (
        <>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                {territoryStore.groups && territoryStore.groups
                                    .map((group: TerritoryGroup) => (
                                        <TerritoryGroupTableRow
                                            key={group.id}
                                            editable={false}
                                            group={group}
                                        >
                                            <TerritoriesTable
                                                territories={group.territories || []}
                                                onTerritoryAssign={openAssignTerritoryDialog}
                                                onTerritoryFocus={showTerritoryDialog}
                                            />
                                        </TerritoryGroupTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

            </Container>
            {isOpen("assign-territory") && <AssignTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("assign-territory")}
                onClose={handleCloseDialog}
            />}
            {isOpen("show-territory") && <ShowTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("show-territory")}
                onClose={handleCloseDialog}
                onAssign={openAssignTerritoryDialog}
            />}
        </ >
    );
});