import * as React from 'react';
import { Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react-lite';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import 'moment/locale/ru'
import { useTranslation } from "react-i18next";
import "./S88ReportsTable.css";
import { sum } from '../../utils/dateUtils';

interface AttendanceObject {
    month: number,
    year: number,
    count: string,
    avg: string,
    sum: string
}

interface AttendanceData {
    year: number,
    workdays: AttendanceObject[],
    weekends: AttendanceObject[],
}

interface Props {
    values: AttendanceData[];
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const moment = extendMoment(Moment);

    const ReportRow = (args: { report: AttendanceObject }) => {
        const report = args.report;
        return (
            <TableRow className='report-table-row' key={report.month}>
                <TableCell><i>{moment().year(report.year).month(report.month - 1).format("MMMM")}</i></TableCell>
                <TableCell>{report.count || ''}</TableCell>
                <TableCell>{report.sum || ''}</TableCell>
                <TableCell><b>{report.avg || ''}</b></TableCell>
            </TableRow>
        );
    }

    const SummaryReportRow = (args: { reports: AttendanceObject[], }) => {
        const avg = sum(args.reports, "avg") / args.reports.length;
        return (
            <TableRow style={{ backgroundColor: "#d219801a" }} className='report-table-row' key={avg}>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell><b>{avg.toFixed(1)}</b></TableCell>
            </TableRow>
        );
    }

    const YearRow = (args: { title: string, year: number, reports: AttendanceObject[] }) => {
        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell component="th" scope="row">
                        <h2>{args.year}</h2>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Table className="report-table" size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow >
                                    <TableCell><b>{t(args.title)}</b></TableCell>
                                    <TableCell>{t('count')}</TableCell>
                                    <TableCell>{t('overall')}</TableCell>
                                    <TableCell>{t('average')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {args.reports.map((report: AttendanceObject) => (
                                    <ReportRow key={report.month} report={report} />
                                ))}
                                <SummaryReportRow reports={args.reports} />
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>
            </>
        )
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {props.values.map((value, i) => (
                                    <YearRow
                                        key={i}
                                        title="workdays"
                                        year={value.year}
                                        reports={value.workdays}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {props.values.map((value, i) => (
                                    <YearRow
                                        key={i}
                                        title="weekends"
                                        year={value.year}
                                        reports={value.weekends}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Container>
    );
});