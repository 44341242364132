import moment from "moment";

export const academicMonthIndex = (monthNum: number) => {
    const month = monthNum;
    let monthIndex = monthNum;
    return monthIndex += month < 9 ? 4 : -8;
}

export const monthToAcademic = (academic: number) => {
    const month = academic;
    let monthIndex = academic;
    return monthIndex += month < 5 ? 8 : -4;
}

export const createAcademicDate = (academicMonth: number, academicYear: number): Date => {
    const month = monthToAcademic(academicMonth);
    const year = academicMonth < 5 ? academicYear-1 : academicYear;
    return moment(`${year}-${month}`, "YYYY-M").startOf("month").toDate();
}

export const getAcademicYearRangeFromDate = (date: Date): Date[] => {
    const year = moment(date).year();
    return getAcademicYearRange(year);
}

export const getAcademicYearRange = (year: number): Date[] => {
    const from = moment(`${year-1}-09-01`).toDate();
    const to = moment(`${year}-08-01`).endOf("month").toDate();
    return [from, to];
}

export const sum = (arr: any[], field: string) => arr.reduce((partialSum, report): number => {
    let val = report[field];
    if (typeof val === 'string') {
        val = parseFloat(val);
    }
    return partialSum + val;
}, 0);