import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';
import { LiteratureType } from '../domain/LiteratureType';
import { LiteratureRequest } from '../domain/LiteratureRequest';

export class LiteratureStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    literatureTypes: LiteratureType[] = [];
    literatureRequests: LiteratureRequest[] = [];

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    getLiteratureTypes = () => {
        this.serverStore.get('/literature/type', action((data) => {
            this.literatureTypes = data;  
        }));
    }

    getLiteratureRequests = () => {
        this.serverStore.get('/literature/request', action((data) => {
            this.literatureRequests = data;
        }));
    }

    saveLiteratureType = (type: any) => {
        this.serverStore.post('/literature/type', type, () => {
            this.getLiteratureTypes();
            this.rootStore.notificationsStore.add("new_literature_type_is_saved", "success");
        });
    }

    updateLiteratureType = (id: number, type: LiteratureType) => {
        this.serverStore.patch('/literature/type/' + id, type, () => {
            this.getLiteratureTypes();
            this.rootStore.notificationsStore.add("literature_type_is_updated", "success");
        });
    }

    deleteLiteratureType = (id: number) => {
        this.serverStore.delete('/literature/type/' + id, () => {
            this.getLiteratureTypes();
            this.rootStore.notificationsStore.add("literature_type_is_deleted", "success");
        });
    }

    saveLiteratureRequest = (req: LiteratureRequest) => {
        this.serverStore.post('/literature/request', req, () => {
            this.getLiteratureRequests();
            this.rootStore.notificationsStore.add("literature_request_is_saved", "success");
        });
    }

    updateLiteratureRequest = (id: number, request: LiteratureRequest) => {
        this.serverStore.patch('/literature/request/' + id, request, () => {
            this.getLiteratureRequests();
            this.rootStore.notificationsStore.add("literature_request_is_updated", "success");
        });
    }

    saveBatch = (requests: LiteratureRequest[]) => {
        this.serverStore.post('/literature/request/save-batch', requests, action((data) => {
            this.getLiteratureRequests();
            this.rootStore.notificationsStore.add("literature_requests_are_saved", "success");
        }));
    }
}