import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { Calendar, Event, momentLocalizer, SlotInfo } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useOpenDialog } from '../../hooks/useOpenDialog';
import { CartWitnessingPlace } from '../../domain/CartWitnessingPlace';
import { useStores } from '../../stores';
import { useConfirm } from 'material-ui-confirm';
import CartWitnessingRecordDialog from './CartWitnessingRecordDialog';
import { CartWitnessingRecord } from '../../domain/CartWitnessingRecord';
import { isMobileOnly } from 'react-device-detect';
import { isOverlap } from '../../utils/dateOverlapUtils';
import "./CustomCalendar.css"

interface Props {
    place: CartWitnessingPlace;
}

interface CartEvent extends Event {
    link: CartWitnessingRecord;
}

const localizer = momentLocalizer(moment)

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const confirm = useConfirm();
    const { cartWitnessingStore, publisherStore, notificationsStore } = useStores();
    const [from, setFrom] = React.useState<Date>(new Date());
    const [to, setTo] = React.useState<Date>(new Date());
    const [events, setEvents] = React.useState<CartEvent[]>([]);
    const [selectedRecord, setSelectedRecord] = React.useState<CartWitnessingRecord | undefined>();

    const handleSelectSlot = React.useCallback((slotInfo: SlotInfo) => {
        const from = moment(slotInfo.start);
        const to = moment(slotInfo.end);

        // set same day of month, pick several days is not permitted
        if (from.date() !== to.date()) {
            to.date(from.date());
        }

        // if today - set available time 
        if (moment(from).startOf("day").isSame(moment().startOf("day"))) {
            if (from.isBefore(moment())) {
                const newTime = moment().add(1, "hour");
                from.hour(newTime.hour());
                to.hour(newTime.add(1, "hour").hour());
            }
        } else {
            // if not today - set 11 and 12 hours in the morning
            if (from.hour() < 8) {
                from.hour(11);
                to.hour(12);
            }
        }

        if (from.isAfter(moment())) {
            setFrom(from.toDate());
            setTo(to.toDate());
            openDialog("new-record");
        }

        if (to.isBefore(moment())) {
            notificationsStore.add(t("you_arent_allowed_to_put_your_record_here"), "error");
        }
    }, [setEvents]);

    React.useEffect(() => {
        const events = props.place.records.map(record => {
            let title = '';
            if (record.customPublisher) {
                title = record.customPublisher;
            } else {
                const publisher = publisherStore.publishers.find(publ => publ.id === record.publisherId);
                if (publisher) {
                    title = publisher.lastName;
                }
            }

            return {
                link: record,
                start: moment(record.from).toDate(),
                end: moment(record.to).toDate(),
                title
            }
        })
        setEvents(events);
    }, [cartWitnessingStore.places, publisherStore.publishers]);

    const handleSelectEvent = React.useCallback((event: CartEvent) => {
        setSelectedRecord(event.link);
        openDialog("update-record");
    }, []);

    const close = () => {
        closeDialog();
        setSelectedRecord(undefined);
    }

    const prepareRecord = (data: any) => {
        const record = {
            placeId: props.place.id,
            from: data.from,
            to: data.to,
            locked: data.locked
        } as any;

        if (data.publisher) {
            record.publisherId = data.publisher.id;
            record.customPublisher = undefined;
        }
        if (data.customPublisher) {
            record.customPublisher = data.customPublisher;
            record.publisherId = undefined;
        }
        return record;
    }

    const saveRecord = (data: any) => {
        const record = prepareRecord(data) as CartWitnessingRecord;
        const overlapped = events.filter(ev => isOverlap(ev.link.from, ev.link.to, record.from, record.to));

        if (overlapped.some(event => event.link.locked)) {
            notificationsStore.add(t("you_arent_allowed_to_put_your_record_here"), "error");
            return;
        }

        if (overlapped.length >= 2) {
            confirm({
                title: t("are_you_sure"),
                description: t("cart_witnessing_confirmation_long_text"),
                confirmationText: t("already_called"),
                cancellationText: t("havent_called_yet"),
                confirmationButtonProps: { sx: { color: "green" } },
                cancellationButtonProps: { sx: { color: "red" } }
            }).then(() => {
                cartWitnessingStore.createRecord(record);
            });
        } else {
            cartWitnessingStore.createRecord(record);
        }
    }

    const updateRecord = (data: any) => {
        cartWitnessingStore.updateRecord(data.id, prepareRecord((data)));
    }

    const deleteRecord = (cartRecord: CartWitnessingRecord) => {
        closeDialog();
        setSelectedRecord(undefined);
        cartWitnessingStore.deleteRecord(cartRecord.id);
    }

    const addCompanion = (from: Date, to: Date) => {
        setFrom(from);
        setTo(to);
        openDialog("new-record");
    }

    const eventPropGetter = (event: CartEvent, start: Date, end: Date, isSelected: boolean) => {
        const prop = { event, start, end, isSelected } as any;
        const hasOverlaps = events.filter(ev => isOverlap(ev.start!, ev.end!, start, end));
        if (hasOverlaps.length === 1) {
            prop.style = { backgroundColor: "#d32f2f" };
        }
        if (event.link.locked) {
            prop.style = { backgroundColor: "#009E60" };
        }
        if (moment(end).isBefore(moment())) {
            prop.style = { backgroundColor: "grey" }; 
        }
        return prop;
    }

    const className = isMobileOnly ? 'custom-calendar' : undefined;
    const prevText = isMobileOnly ? "<" : t("previous");
    const nextText = isMobileOnly ? ">" : t("next");

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <div style={{ height: 600 }}>
                    <Calendar
                        className={className}
                        dayLayoutAlgorithm="no-overlap"
                        localizer={localizer}
                        events={events}
                        formats={{ eventTimeRangeFormat: () => '' }}
                        onSelectEvent={handleSelectEvent}
                        onSelectSlot={handleSelectSlot}
                        selectable
                        startAccessor="start"
                        endAccessor="end"
                        defaultView={isMobileOnly ? 'day' : 'month'}
                        min={new Date(0, 0, 0, 8, 0, 0)}
                        max={new Date(0, 0, 0, 21, 0, 0)}
                        views={{ month: true, week: true, day: true }}
                        eventPropGetter={eventPropGetter}
                        messages={{
                            date: t("date"),
                            today: t("today"),
                            time: t("time"),
                            event: t("event"),
                            allDay: t("all_day"),
                            week: t("week"),
                            work_week: t("work_week"),
                            day: t("day"),
                            month: t("month"),
                            previous: prevText,
                            next: nextText,
                            yesterday: t("yesterday"),
                            tomorrow: t("tomorrow"),
                            agenda: t("agenda"),
                            noEventsInRange: t("no_events_in_range"),
                        }}
                    />
                </div>
            </Paper>
            {isOpen("new-record") && <CartWitnessingRecordDialog
                isOpen={isOpen("new-record")}
                from={from}
                to={to}
                onSubmit={saveRecord}
                onClose={closeDialog}
            />}
            {isOpen("update-record") && <CartWitnessingRecordDialog
                isOpen={isOpen("update-record")}
                cartRecord={selectedRecord}
                onSubmit={updateRecord}
                onClose={close}
                onDelete={deleteRecord}
                onCompanionAdd={addCompanion}
            />}
        </Container>
    );
});