import * as React from 'react';
import Panel from '../../panel/Panel';
import { useStores } from '../../../stores';
import { Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { AccountingAnalysis } from '../../../domain/AccountingAnalysis';
import AccountingAnalysisTable from './AccountingAnalysisTable';
import AccountingAnalysisChart from './AccountingAnalysisChart';
import AccountingAnalysisSummary from './AccountingAnalysisSummary';

export default observer(() => {
    const { t } = useTranslation();
    const { accountingStore } = useStores();
    const [analysis, setAnalysis] = React.useState<AccountingAnalysis[] | undefined>();
    const [tab, setTab] = React.useState(0);

    React.useEffect(() => {
        accountingStore.getAnalysis((analysis) => {
            setAnalysis(analysis)
        });
    }, []);

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <Panel
            title={t("accounting")}
            headerItems={[]}>

            <Tabs value={tab} onChange={handleTabChange}>
                <Tab label={t("graph")} />
                <Tab label={t("table")} />
                <Tab label={t("summary")} />
            </Tabs>
            <div role="tabpanel" hidden={tab !== 0}>
                <AccountingAnalysisChart analysis={analysis} />
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <AccountingAnalysisTable analysis={analysis} />
            </div>
            <div role="tabpanel" hidden={tab !== 2}>
                <AccountingAnalysisSummary />
            </div>
        </Panel >
    );
});