import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useStores } from '../../../stores';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Publisher } from '../../../domain/Publisher';
import { observer } from 'mobx-react-lite';
import RestoreIcon from '@mui/icons-material/Restore';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
import Can from '../../../components/Can';
import moment from 'moment';
import EditDeletedPublisherDialog from './EditDeletedPublisherDialog';
import { useOpenDialog } from '../../../hooks/useOpenDialog';

export default observer(() => {
    const { t } = useTranslation();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const { publisherStore } = useStores();
    const [publishers, setPublishers] = React.useState<Publisher[]>();
    const [selectedPublisher, setSelectedPublisher] = React.useState<Publisher>();

    React.useEffect(() => {
        publisherStore.getDeletedPublishers((data) => {
            setPublishers(data);
        });
    }, []);

    const handleRestore = (id: number) => {
        publisherStore.restorePublisher(id, () => {
            publisherStore.getDeletedPublishers((data) => {
                setPublishers(data);
            });
        });
    }

    const openEditDialog = (publisher: Publisher) => {
        setSelectedPublisher(publisher);
        openDialog("edit-publisher");
    }

    const closeEditDialog = () => {
        setSelectedPublisher(undefined);
        closeDialog();
    }

    const submitEditedPublisher = (publisher: Publisher) => {
        publisherStore.updatePublisher(publisher.id, publisher, () => {
            publisherStore.getDeletedPublishers((data) => {
                setPublishers(data);
            });
            closeDialog();
        });
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <TableContainer>
                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("surname")}</TableCell>
                                <TableCell>{t("date")}</TableCell>
                                <TableCell>{t("notes")}</TableCell>
                                <TableCell>{t("actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {publishers && publishers
                                .map((publisher: Publisher) => (
                                    <TableRow key={publisher.id}>
                                        <TableCell>{publisher.lastName}</TableCell>
                                        <TableCell>{moment(publisher.deletedAt).format("yyyy.MM.DD")}</TableCell>
                                        <TableCell>{publisher.notes}</TableCell>
                                        <TableCell>
                                            <Can do="manage" on='Publisher'>
                                                <IconButton onClick={() => handleRestore(publisher.id)}>
                                                    <RestoreIcon color="primary" />
                                                </IconButton>
                                                <IconButton onClick={() => openEditDialog(publisher)} >
                                                    <EditIcon />
                                                </IconButton>
                                            </Can>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <EditDeletedPublisherDialog
                isOpen={isOpen("edit-publisher")}
                publisher={selectedPublisher}
                onSubmit={submitEditedPublisher}
                onClose={closeEditDialog} />
        </Container>
    );
});