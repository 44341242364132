import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";
import { FieldServiceGroup } from '../../../domain/FieldServiceGroup';
import { Publisher } from '../../../domain/Publisher';
import LiteratureTableRow from './LiteratureTableRow';
import { LiteratureRequest } from '../../../domain/LiteratureRequest';
import { LiteratureType } from '../../../domain/LiteratureType';

interface Props {
    groups: FieldServiceGroup[];
    publishers: Publisher[];
    types: LiteratureType[];
    requests: LiteratureRequest[];
}

export default (props: Props) => {
    const { t } = useTranslation();

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell />
                        {props.types && props.types.map(type => (
                            <TableCell key={type.name} align="center">{type.name}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.groups && props.groups
                        .slice() // copy the array because sorting mutates original array
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((group: FieldServiceGroup) => (
                            <LiteratureTableRow
                                key={group.id}
                                group={group}
                                publishers={props.publishers.filter(publisher => publisher.serviceGroup && publisher.serviceGroup.id === group.id)}
                                types={props.types}
                                requests={props.requests}
                            />
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};