import * as React from 'react';
import { Collapse, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";
import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScheduleTableSingleRow from './ScheduleTableSingleRow';

interface Props {
    entries: ScheduleEntry[];
    assigned: number[];
    editable?: boolean;
    onEntryUpdate?: (entry: ScheduleEntry) => void;
    onEntryEdit?: (entry: ScheduleEntry) => void;
    onEntryHistory?: (entry: ScheduleEntry) => void;
}

export default (props: Props) => {
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = React.useState(false);

    return (
        <>
            <TableRow>
                <TableCell style={{ minWidth: "250px" }}>
                    <IconButton
                        size="small"
                        onClick={() => setCollapsed(!collapsed)} >
                        {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </IconButton>
                    {props.entries[0].name}
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={6}>
                    <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                        <Table size="small">
                            <TableBody>
                                {props.entries.map(entry => (
                                    <ScheduleTableSingleRow
                                        key={entry.id}
                                        name={t("number_hall", { number: entry.room })}
                                        entry={entry}
                                        assigned={props.assigned}
                                        editable={props.editable}
                                        onEntryUpdate={props.onEntryUpdate}
                                        onEntryEdit={props.onEntryEdit}
                                        onEntryHistory={props.onEntryHistory}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};