import { Locale } from 'date-fns';
import { enGB, lv, ru } from 'date-fns/locale';
import { ruRU, enUS } from '@mui/x-data-grid';

export const getLocale = (): Locale => {
    const envLang = process.env.REACT_APP_LANG;
    let locale = enGB;
    if (envLang === "ru") {
        locale = ru;
    } else if (envLang === "lv") {
        locale = lv;
    }
    return locale;
}

export const getGridLocale = () => {
    const envLang = process.env.REACT_APP_LANG;
    let locale = enUS;
    if (envLang === "ru") {
        locale = ruRU;
    }
    return locale;
}