import L, { LatLng, LatLngExpression } from "leaflet";
import { Territory } from "../../domain/Territory";

export const stringToLatLng = (str: string): LatLngExpression[] => {
    return JSON.parse(str) as LatLngExpression[];
}

export const getShapeCenter = (shape: LatLngExpression[]): L.LatLng => {
    const polygon = L.polygon(shape);
    const bounds = polygon.getBounds();
    return bounds.getCenter();
}

export const boundsId = (arr: LatLng[], territory?: Territory) => {
    const terrId = territory ? territory.id : Math.floor(Math.random() * 10000);
    return terrId + arr.map(ll => ll.lat + ll.lng).join(",")
}