import { FieldServiceReport } from "../domain/FieldServiceReport";
import { ServiceAssignment } from "../domain/Publisher";

export const reportColor = (report?: FieldServiceReport): string | undefined => {
    if (report) {
        let color = serviceAssignmentColor(report.serviceAssignment);
        if (!color && report.empty) {
            return emptyReport();
        } else return color;
    }
}

export const emptyReport = (): string => {
    return '#c628281c';
}

export const serviceAssignmentColor = (serviceAssignment?: ServiceAssignment): string | undefined => {
    if (serviceAssignment === ServiceAssignment.RegularPioneer) {
        return '#2e7d3214';
    } else if (serviceAssignment === ServiceAssignment.AuxiliaryPioneer) {
        return '#7E238708';
    } else if (serviceAssignment === ServiceAssignment.SpecialPioneer || serviceAssignment === ServiceAssignment.Missionary) {
        return '#1565c021';
    }
}

export const reportClass = (report?: FieldServiceReport): string | undefined => {
    if (report) {
        if (report.serviceAssignment === ServiceAssignment.RegularPioneer) {
            return 'regular-pioneer';
        } else if (report.serviceAssignment === ServiceAssignment.AuxiliaryPioneer) {
            return 'aux-pioneer';
        } else if (report.serviceAssignment === ServiceAssignment.SpecialPioneer || report.serviceAssignment === ServiceAssignment.Missionary) {
            return 'special-pioneer';
        } else if (report.empty) {
            return 'non-regular';
        }
    } else {
        return 'empty';
    }
}