import * as React from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AbilityContext } from '../../../components/Can';
import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import PublisherTextField from './PublisherTextField';
import ScheduleSpeakerInput from './ScheduleSpeakerInput';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';
import { isMobileOnly } from 'react-device-detect';

interface Props {
    name?: string;
    entry: ScheduleEntry;
    assigned: number[];
    editable?: boolean;
    onEntryUpdate?: (entry: ScheduleEntry) => void;
    onEntryEdit?: (entry: ScheduleEntry) => void;
    onEntryHistory?: (entry: ScheduleEntry) => void;
}

export default observer((props: Props) => {
    const { publisherStore } = useStores();
    const ability = React.useContext(AbilityContext);

    let isEditable = ability.can("manage", "ScheduleEntry");
    if (props.editable != undefined) {
        isEditable = props.editable;
    }
    // if (props.entry.participants === "no") {
    //     isEditable = false;
    // }

    const handleEntryEdit = () => props.onEntryEdit && props.onEntryEdit(props.entry);
    const handleEntryHistory = () => props.onEntryHistory && props.onEntryHistory(props.entry);

    // const hasAssignment = !!props.entry.assignmentId;
    const myPublisher = publisherStore.myPublisher;
    const isMyPublisher = myPublisher && (myPublisher.id === props.entry.speakerId || myPublisher.id === props.entry.companionId);
    const isImpromptu = !!props.entry.impromptuId;

    const titleWidth = isMobileOnly ? "250px" : "500px";
    const backgroundColor = isMyPublisher ? "#2e7d3214" : isImpromptu ? "#c628281c" : undefined
    return (
        <TableRow style={{ backgroundColor }}>
            <TableCell style={{ minWidth: titleWidth, maxWidth: titleWidth }}>{props.name || props.entry.name}</TableCell>
            <TableCell style={{ minWidth: "220px", maxWidth: "220px" }}>
                <ScheduleSpeakerInput
                    entry={props.entry}
                    editable={isEditable}
                    assigned={props.assigned}
                    onEntryUpdate={props.onEntryUpdate}
                />
            </TableCell>
            <TableCell style={{ minWidth: "220px", maxWidth: "220px" }} >
                {props.entry.companionNeeded && <PublisherTextField
                    entry={props.entry}
                    field="companion"
                    editable={isEditable}
                    assigned={props.assigned}
                    onEntryUpdate={props.onEntryUpdate}
                />}
            </TableCell>
            <TableCell>
                {isEditable && (
                    <IconButton size='small' onClick={handleEntryEdit}>
                        <EditIcon color="primary" />
                    </IconButton>
                )}
                {isEditable && (
                    <IconButton size='small' onClick={handleEntryHistory}>
                        <CalendarMonthIcon />
                    </IconButton>
                )}
            </TableCell>
        </TableRow>
    );
});