import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Can from '../../../components/Can';
import { useTranslation } from "react-i18next";
import SideBarMenuItem from '../SideBarMenuItem';

export default () => {
    const { t } = useTranslation();
    return (
        <Can do="read" on='ScheduleEntry'>
            <SideBarMenuItem id="side_menu_item_schedule" to='/schedule' text={t("schedule")}>
                <CalendarTodayIcon />
            </SideBarMenuItem>
        </Can>
    )
}
