import { useStores } from '../../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { KnowledgeTopic } from '../../../domain/KnowledgeTopic';

interface Props {
    isOpen: boolean;
    topic?: KnowledgeTopic;
    onDelete: (id: number) => void;
    onClose: () => void;
}

interface FormValues {
    id: number;
    name: string;
    description?: string
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { knowledgeStore } = useStores();

    const initialValues = { name: '' } as FormValues;
    if (props.topic) {
        initialValues.id = props.topic.id;
        initialValues.name = props.topic.name;
        initialValues.description = props.topic.description;
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: (values) => {
            if (props.topic) {
                knowledgeStore.updateKnowledgeTopic(values.id, {
                    name: values.name,
                    description: values.description
                });
            } else {
                knowledgeStore.saveKnowledgeTopic({
                    name: values.name,
                    description: values.description
                });
            }

            props.onClose();
        },
    });

    return (
        <Dialog open={props.isOpen} fullWidth={true} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{t("manage_topic")}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="name"
                        label={t("name")}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.name || ''}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        autoFocus
                        margin="normal"
                        id="description"
                        label={t("description")}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.description || ''}
                        onChange={formik.handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    {props.topic && <Button color="warning" onClick={() => props.onDelete(props.topic!.id)}>{t("delete")}</Button>}
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button color="primary" type="submit">{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});