import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DeleteIcon from '@mui/icons-material/Delete';
import { Publisher } from '../../../domain/Publisher';
import { useTranslation } from 'react-i18next';
import { TerritoryRequest } from '../../../domain/TerritoryRequest';

interface Props {
    publisher: Publisher;
    onRequestAssign: (request: TerritoryRequest) => void;
    onRequestDelete: (request: TerritoryRequest) => void;
}

export default (props: Props) => {
    const { t } = useTranslation();

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("group")}</TableCell>
                        <TableCell>{t("territory")}</TableCell>
                        <TableCell>{t("notes")}</TableCell>
                        <TableCell>{t("actions")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.publisher.territoryRequests!.map(request => {
                        return <TableRow key={request.id}>
                            <TableCell>
                                {request.territory!.territoryGroup!.name}
                            </TableCell>
                            <TableCell>
                                <b>{request.territory!.title}</b>
                            </TableCell>
                            <TableCell>
                                {request.notes}
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => props.onRequestAssign(request)}>
                                    <AssignmentIndIcon />
                                </IconButton>
                                <IconButton onClick={() => props.onRequestDelete(request)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};