import { Grid, Paper, Table, TableBody, TableContainer, Toolbar, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import { TerritoryGroup } from '../../../domain/TerritoryGroup';
import TerritoryGroupTableRow from '../management/TerritoryGroupTableRow';
import { Territory } from '../../../domain/Territory';
import { LatLng } from 'leaflet';
import TerritoryMap from '../map/TerritoryMap';
import { boundsId } from '../mapUtils';
import TerrioriesForRequestTable from './TerrioriesForRequestTable';
import { TerritoryRequest } from '../../../domain/TerritoryRequest';
import MyTerritoryRequestsTable from './MyTerritoryRequestsTable';

interface Props {
    requests: TerritoryRequest[];
    currentTerritory?: Territory;
    onTerritoryFocus: (territory: Territory) => void;
    onTerritorySelection: (territory: Territory) => void;
    onTerritoryRequest: (territory: Territory) => void;
    onTerritoryRequestDeletion: (request: TerritoryRequest) => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { territoryStore } = useStores();

    return (
        <Grid container spacing={3}>
            <Grid item xs={3} >
                <Paper style={{ height: "50vh" }} sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                    <Toolbar>
                        <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div">
                            {t("territory_groups")}
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                {territoryStore.groups && territoryStore.groups
                                    .filter(group => !group.hidden)
                                    .map((group: TerritoryGroup) => (
                                        <TerritoryGroupTableRow
                                            key={group.id}
                                            group={group}
                                            editable={false}
                                        >
                                            <TerrioriesForRequestTable
                                                territories={group.territories || []}
                                                onTerritoryFocus={props.onTerritoryFocus}
                                                onTerritoryRequest={props.onTerritoryRequest}
                                            />
                                        </TerritoryGroupTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Paper style={{ height: "28vh" }} sx={{ mt: 2, p: 2, display: 'flex', flexDirection: 'column' }} >
                    <Toolbar>
                        <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div">
                            {t("my_requests")}
                        </Typography>
                    </Toolbar>
                    <MyTerritoryRequestsTable
                        requests={props.requests}
                        onRequestDelete={props.onTerritoryRequestDeletion}
                    />
                </Paper>

            </Grid>

            <Grid item xs={9}>
                <Paper style={{ height: "80vh" }} sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                    <TerritoryMap
                        style={{ height: "100%", width: "100%" }}
                        currentTerritory={props.currentTerritory}
                        onPolygonClick={props.onTerritorySelection}
                        tooltipOnHover
                        scrollWheelZoom
                        polygons={territoryStore.groups
                            .filter(group => !group.hidden)
                            .filter(group => !!group.territories)
                            .map(group => (
                                group.territories!.map((terr) => {
                                    const bounds = JSON.parse(terr.boundary) as LatLng[][];
                                    return bounds.map(b => ({
                                        id: boundsId(b, terr),
                                        color: group.color,
                                        bounds: b,
                                        title: `${group.name} - ${terr.title}`,
                                        territory: terr
                                    }))
                                }).flat(1)
                            )).flat(1)
                        }
                    />
                </Paper>
            </Grid>
        </Grid>
    );
});