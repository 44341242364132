import * as React from 'react';
import Panel from '../../panel/Panel';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import SaveIcon from '@mui/icons-material/Save';
import { Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, TextareaAutosize } from '@mui/material';
import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import moment from 'moment';

export default observer(() => {
    const { t } = useTranslation();
    const { scheduleStore } = useStores();
    const [entries, setEntries] = React.useState<ScheduleEntry[]>([]);

    React.useEffect(() => {
        scheduleStore.getSpeechEntries((entries) => setEntries(entries.reverse()));
    }, []);

    const updateEntryInTable = (index: number, entry: ScheduleEntry) => {
        const newArr = [...entries];
        newArr[index] = entry;
        setEntries(newArr);
    }

    const saveEntry = (entry: ScheduleEntry) => {
        scheduleStore.updateEntry(entry);
    }

    return (
        <Panel
            title={t("schedule_public_speeches")}
            headerItems={[]}>
            <Container maxWidth="xl">
                <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                <TableCell width="2%"></TableCell>
                                    <TableCell width="15%">{t("date")}</TableCell>
                                    <TableCell width="18%">{t("speaker")}</TableCell>
                                    <TableCell width="40%">{t("title")}</TableCell>
                                    <TableCell width="20%">{t("notes")}</TableCell>
                                    <TableCell width="5%">{t("actions")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {entries.map((entry, i) => (
                                    <TableRow key={entry.id}>
                                        <TableCell>{`${i+1}`}</TableCell>
                                        <TableCell><b>{`${entry.week!.name} (${moment(entry.week!.date).format("YYYY")})`}</b></TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                id="name"
                                                label={t("speaker")}
                                                fullWidth
                                                variant="standard"
                                                value={entry.customSpeaker || ''}
                                                onChange={(e) => {
                                                    entry.customSpeaker = e.target.value;
                                                    updateEntryInTable(i, entry)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                id="name"
                                                label={t("title")}
                                                fullWidth
                                                variant="standard"
                                                value={entry.name || ''}
                                                onChange={(e) => {
                                                    entry.name = e.target.value;
                                                    updateEntryInTable(i, entry)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextareaAutosize
                                                id="notes"
                                                value={entry.notes || ''}
                                                minRows={6}
                                                placeholder={t("notes")}
                                                style={{ width: "100%" }}
                                                onChange={(e) => {
                                                    entry.notes = e.target.value;
                                                    updateEntryInTable(i, entry)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => saveEntry(entry)}>
                                                <SaveIcon color='primary' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Container>
        </Panel >
    );
});