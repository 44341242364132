import { useFormik } from 'formik';
import { useStores } from '../stores';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";
import { getLocale } from '../utils/localeUtils';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { reportStore } = useStores();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { 
            year1: moment().subtract(1, "year").format('YYYY-MM-DD'),
            year2: moment().format('YYYY-MM-DD')
    } ,
        onSubmit: (values) => {
            reportStore.downloadS88Report({
                year1: values.year1,
                year2: values.year2
            });
        }
    });

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("download_s_88")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("year")}
                            views={['year']}
                            value={formik.values.year1}
                            onChange={value => {
                                formik.setFieldValue('year1', value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" name="year1" />}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("year")}
                            views={['year']}
                            value={formik.values.year2}
                            onChange={value => {
                                formik.setFieldValue('year2', value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" name="year2" />}
                        />
                    </LocalizationProvider>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});