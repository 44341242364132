import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import Can from '../../../components/Can';
import { useTranslation } from "react-i18next";
import SideBarMenuItem from '../SideBarMenuItem';

export default () => {
    const { t } = useTranslation();
    return (
        <Can do="read" on='Cart'>
            <SideBarMenuItem id="side_menu_item_carts" to='/carts' text={t("carts")}>
                <DocumentScannerOutlinedIcon />
            </SideBarMenuItem>
        </Can>
    )
}
