import { Divider, List } from "@mui/material";
import SideBarToolbar from './SideBarToolbar';
import SideBarMenu from './SideBarMenu';
import SideBarReportsMenu from './menu-items/SideBarReportsMenu';
import SideBarCongregationMenu from './menu-items/SideBarCongregationMenu';
import SideBarTerritoriesMenu from './menu-items/SideBarTerritoriesMenu';
import SideBarExtraMenu from './menu-items/SideBarExtraMenu';
import SideBarHomeMenu from './menu-items/SideBarHomeMenu';
import SideBarCartMenu from './menu-items/SideBarCartMenu';
import SideBarScheduleMenu from './menu-items/SideBarScheduleMenu';
import SideBarUsersMenu from './menu-items/SideBarUsersMenu';
import SideBarSettingsMenu from './menu-items/SideBarSettingsMenu';
import SideBarLogoutMenu from './menu-items/SideBarLogoutMenu';

export default () => {
    return (
        <SideBarMenu >
            <SideBarToolbar />
            <List component="nav">
                <SideBarHomeMenu />
                <SideBarCartMenu />
                <SideBarScheduleMenu />
                <SideBarCongregationMenu />
                <SideBarReportsMenu />
                <SideBarTerritoriesMenu />
                <SideBarExtraMenu />
                <SideBarUsersMenu />

                <Divider sx={{ my: 1 }} />

                <SideBarSettingsMenu />
                <SideBarLogoutMenu />
            </List>
        </SideBarMenu >
    )
}