import Container from '@mui/material/Container';
import { useStores } from '../../../stores';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import OverseerReportsDialog from './OverseerReportsDialog';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import LoadingDimmer from '../../../components/LoadingDimmer';

interface Props {
    index: number;
    tab: number;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { serverStore, applicationStore, publisherStore, reportStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();


    return (
        <div role="tabpanel" hidden={props.tab !== props.index} >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container>
                    <Grid item xs={3} padding={2}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {t("backup")}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    {t("backup_short_description")}
                                </Typography>
                                <Typography variant="body2">
                                    {t("backup_long_description")}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    size="small"
                                    onClick={() => applicationStore.downloadDbDump()}>
                                    {t("download")}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={3} padding={2}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {t("publishers")}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    {t("export_publishers_short_description")}
                                </Typography>
                                <Typography variant="body2">
                                    {t("export_publishers_long_description")}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    size="small"
                                    onClick={() => publisherStore.exportPublishersExcel()}>
                                    {t("download")}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={3} padding={2}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {t("reports")}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    {t("export_all_reports_short_description")}
                                </Typography>
                                <Typography variant="body2">
                                    {t("export_all_reports_long_description")}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    size="small"
                                    onClick={() => reportStore.exportAllReports()}>
                                    {t("download")}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={3} padding={2}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {t("Circuit Overseer")}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    {t("circuit_overseer_reports_short_description")}
                                </Typography>
                                <Typography variant="body2">
                                    {t("circuit_overseer_reports_long_description")}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    size="small"
                                    onClick={() => openDialog("overseer-dialog")}>
                                    {t("download")}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <OverseerReportsDialog
                isOpen={isOpen("overseer-dialog")}
                onClose={closeDialog}
            />
            <LoadingDimmer enabled={serverStore.serverRequestLoading} />
        </div>
    );
});