import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import { Publisher } from '../../../domain/Publisher';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AllTerritoryRequestsTable from './AllTerritoryRequestsTable';
import { TerritoryRequest } from '../../../domain/TerritoryRequest';
import { useConfirm } from 'material-ui-confirm';
import { isMobileOnly } from 'react-device-detect';
import MobileAllTerritoryRequestsTable from './MobileAllTerritoryRequestsTable';


export default observer(() => {
    const { t } = useTranslation();
    const { publisherStore, territoryStore } = useStores();
    const [publishers, setPublishers] = React.useState<Publisher[]>([]);
    const confirm = useConfirm();

    React.useEffect(() => {
        publisherStore.getPublishersWithTerritoryRequests((data) => {
            setPublishers(data);
        })
    }, []);

    const assignRequest = (request: TerritoryRequest) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("do_you_want_to_assign_the_territory")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            territoryStore.assignRequestedTerritory(request.id, () => {
                publisherStore.getPublishersWithTerritoryRequests((data) => {
                    setPublishers(data);
                })
            });
        });
    }

    const deleteRequest = (request: TerritoryRequest) => {
        territoryStore.deleteTerritoryRequest(request.id, () => {
            publisherStore.getPublishersWithTerritoryRequests((data) => {
                setPublishers(data);
            })
        });
    }


    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {publishers.map(publ => {
                return <Accordion key={publ.id} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography><b>{`${publ.lastName} ${publ.firstName}`}</b> - {publ.territoryRequests!.length}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {isMobileOnly ? <MobileAllTerritoryRequestsTable
                            publisher={publ}
                            onRequestAssign={assignRequest}
                            onRequestDelete={deleteRequest}
                        /> : <AllTerritoryRequestsTable
                            publisher={publ}
                            onRequestAssign={assignRequest}
                            onRequestDelete={deleteRequest}
                        />}
                    </AccordionDetails>
                </Accordion>
            })}
        </Container>
    );
});