import { useFormik } from 'formik';
import { useStores } from '../stores';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";
import { Attendance } from '../domain/Attendance';
import * as yup from 'yup';
import { getLocale } from '../utils/localeUtils';

interface Props {
    attendance?: Attendance;
    isOpen: boolean;
    onClose: () => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { attendanceStore } = useStores();

    const initialValues = props.attendance ? {
        date: moment(props.attendance.date).format('YYYY-MM-DD'),
        number: props.attendance.number
    } : {
        date: moment().format('YYYY-MM-DD'),
        number: 0
    };

    const schema = yup.object().shape({
        date: yup.date().required(t("field_is_required", { name: t("date") })),
        number: yup.number().required(t("field_is_required", { name: t("amount") }))
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues,
        onSubmit: (values, { resetForm }) => {
            if (props.attendance) {
                attendanceStore.updateAttendance(props.attendance.id, values);
            } else {
                attendanceStore.saveAttendance(values);
            }

            setTimeout(() => {
                attendanceStore.getWorkdays();
                attendanceStore.getWeekends();
            }, 1000);
            props.onClose();
            resetForm();
        }
    });

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("add_attendance")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("date")}
                            maxDate={new Date()}
                            value={formik.values.date}
                            onChange={value => {
                                formik.setFieldValue('date', value ? moment(value).format('YYYY-MM-DD') : value);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={!!formik.errors.date}
                                    helperText={formik.errors.date && formik.errors.date as string}
                                    margin="normal"
                                    name="date"
                                />}
                        />
                    </LocalizationProvider>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="number"
                        label={t("amount")}
                        type="number"
                        fullWidth
                        variant="standard"
                        error={!!formik.errors.number}
                        helperText={formik.errors.number && formik.errors.number as string}
                        value={formik.values.number}
                        onChange={formik.handleChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});