import * as React from 'react';
import { useStores } from '../../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Publisher } from '../../../domain/Publisher';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { getLocale } from '../../../utils/localeUtils';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TerritoryAssignment } from '../../../domain/TerritoryAssignment';

interface Props {
    assignment: TerritoryAssignment;
    isOpen: boolean;
    onClose: () => void;
    onAssignmentChange: (asssignment: TerritoryAssignment) => void;
    disableReturnedAt?: boolean;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { publisherStore } = useStores();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: props.assignment,
        onSubmit: (values, { resetForm }) => {
            props.onAssignmentChange(values);
            resetForm();
            props.onClose();
        },
    });

    const { values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                    {t("assignment")}
                </DialogTitle>
                <DialogContent>
                    <b>{`${props.assignment.territory!.territoryGroup!.name} - ${props.assignment.territory!.title}`}</b>
                    <Autocomplete
                        disablePortal={false}
                        id="publisher"
                        options={publisherStore.publishers.filter(publ => !publ.inactive).map(publ => publ.id)}
                        isOptionEqualToValue={(option, value) => option === value}
                        getOptionLabel={(publisherId: number) => {
                            const publisher: Publisher = publisherStore.publisherById(publisherId);
                            return `${publisher.lastName} ${publisher.firstName}`; 
                        }}
                        value={values.publisherId}
                        renderInput={(params) => <TextField {...params} margin="normal" label={t("publisher")} />}
                        onChange={(e, publisherId) => {
                            if (publisherId) {
                                formik.setFieldValue("publisherId", publisherId);
                            }
                        }}
                    />

                    <TextField
                        margin="normal"
                        id="notes"
                        style={{ minHeight: 50, maxHeight: 150 }}
                        fullWidth
                        type="text"
                        variant="standard"
                        value={values.notes}
                        placeholder={t("notes")}
                        onChange={formik.handleChange}
                    />

                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("taken_at")}
                            value={formik.values.takenAt}
                            onChange={value => {
                                formik.setFieldValue('takenAt', value ? moment(value).format('YYYY-MM-DD') : value);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={!!formik.errors.takenAt}
                                    helperText={formik.errors.takenAt && formik.errors.takenAt as string}
                                    margin="normal"
                                    name="date"
                                />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("returned_at")}
                            disabled={!!props.disableReturnedAt}
                            value={formik.values.returnedAt}
                            onChange={value => {
                                formik.setFieldValue('returnedAt', value ? moment(value).format('YYYY-MM-DD') : value);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={!!formik.errors.returnedAt}
                                    helperText={formik.errors.returnedAt && formik.errors.returnedAt as string}
                                    margin="normal"
                                    name="date"
                                />}
                        />
                    </LocalizationProvider>


                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type="submit">{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});