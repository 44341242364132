import { observer } from "mobx-react-lite";
import { useStores } from "../../../stores";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from "react-i18next";


export default observer(() => {
    const { t } = useTranslation();
    const { userStore } = useStores();

    const handleLogoutClick = () => {
        userStore.logout();
    }
    return (
        <ListItemButton id="side_menu_item_logout" onClick={handleLogoutClick} >
            <ListItemIcon>
                <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t("logout")} />
        </ListItemButton>
    )
})
