import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { FieldServiceReport } from '../domain/FieldServiceReport';
import { ServerStore } from './ServerStore';
import { ServiceAssignment } from '../domain/Publisher';

export class ReportStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    serviceReports: {
        [publisherId: string]: FieldServiceReport[];
    } = {};
    submitted: boolean = true;

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    reset() {
        this.serviceReports = {};
    }

    shouldPublisherSubmitReport = (publisherId: number, date: string) => {
        this.serverStore.get(`/s4-reports/check?publisherId=${publisherId}&date=${date}`, (result: boolean) => {
            this.submitted = result;
        }, console.log)
    }

    getPublisherServiceReports = (publisherId: number) => {
        this.serverStore.get('/s4-reports?publisherId=' + publisherId, action((data) => {
            this.serviceReports[publisherId] = data;
        }))
    }

    getPivotByAppointment = (assignment: ServiceAssignment | string, callback: (result: FieldServiceReport[]) => void) => {
        this.serverStore.get('/s4-reports?assignment=' + assignment, action((data) => {
            callback(data)
        }))
    }

    getPivotAttendance = (callback: (result: any[]) => void) => {
        this.serverStore.get('/s88-reports', action((data) => {
            callback(data)
        }))
    }

    saveS4Report = (report: FieldServiceReport, callback?: (result: boolean) => void) => {
        if (report.id) {
            this.updateS4Report(report, callback);
        } else {
            this.createS4Report(report, callback);
        }
    }

    createS4Report = (report: FieldServiceReport, callback?: (result: boolean) => void) => {
        this.serverStore.post('/s4-reports', report, action((data) => {
            this.rootStore.notificationsStore.add("service_report_is_submitted", "success")
            this.getPublisherServiceReports(report.publisherId);
            callback && callback(true);
        }));
    }

    updateS4Report = (report: FieldServiceReport, callback?: (result: boolean) => void) => {
        this.serverStore.put('/s4-reports/' + report.id, report, action((data) => {
            callback && callback(true)
            this.rootStore.notificationsStore.add("service_report_is_updated", "success");
            this.getPublisherServiceReports(report.publisherId);
        }));
    }

    saveBatch = (reports: FieldServiceReport[]) => {
        this.serverStore.post('/s4-reports/save-batch', reports, action((data) => {
            this.rootStore.notificationsStore.add("service_reports_are_updated", "success");
        }));
    }

    deleteReport = (id: number, publisherId: number, callback?: (result: boolean) => void) => {
        this.serverStore.delete(`/s4-reports/${id}`, action((data) => {
            this.rootStore.notificationsStore.add("service_report_is_deleted", "success");
            this.getPublisherServiceReports(publisherId);
            callback && callback(true)
        }));
    }

    downloadS1Report = (data: any) => {
        this.serverStore.download('/s1-reports/pdf', data)
    }

    downloadS21Report = (data: any) => {
        this.serverStore.download('/s21-reports/pdf', data)
    }

    downloadS88Report = (data: any) => {
        this.serverStore.download('/s88-reports/pdf', data)
    }

    uploadS21Report = (file: any, callback?: () => void) => {
        this.serverStore.upload('/s21-reports/upload', file[0], () => {
            callback && callback();
        });
    }

    uploadS88Report = (file: any, callback?: () => void) => {
        this.serverStore.upload('/s88-reports/upload', file[0], () => {
            callback && callback();
        });
    }

    downloadAssignmentExcel = (assignment: string, date: Date) => {
        this.serverStore.download('/s4-reports/excel', { serviceAssignment: assignment, date });
    }

    exportAllReports = () => {
        this.serverStore.download('/s21-reports/export');
    }

    exportOverseerReports = (data: any) => {
        this.serverStore.download('/overseer-reports/export', data);
    }
}