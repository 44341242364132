import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { User } from '../../domain/User';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";
import Can from '../../components/Can';
import NewUserDialog from './NewUserDialog';
import UpdateUserDialog from './UpdateUserDialog';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import { useConfirm } from 'material-ui-confirm';

export default observer(() => {
    const { t } = useTranslation();
    const { userStore, publisherStore } = useStores();
    const confirm = useConfirm();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [selectedUser, setSelectedUser] = React.useState<User>();

    React.useEffect(() => {
        userStore.getAllUsers();
        publisherStore.getAllPublishers();
    }, []);

    const close = () => {
        setSelectedUser(undefined);
        closeDialog()
    }

    const open = (type: string, user?: User) => {
        if (user) {
            setSelectedUser(user);
        }
        openDialog(type);
    }

    const deleteUser = (user: User) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")} ${user.username}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            userStore.deleteUser(user);
        });
    }

    const getPublisherName = (publisherId?: number): string => {
        const publisher = publisherStore.publishers.find(p => p.id === publisherId);
        if (publisher) {
            return `${publisher.firstName} ${publisher.lastName}`;
        } else return '';
    }

    return (
        <Panel
            title={t("users")}
            headerItems={[
                <Can key="new_user_btn" do="create" on='User'>
                    <Button
                        id="btn_new_user"
                        type="button"
                        color="inherit"
                        onClick={() => open("new-user")}>
                        {t("add_new_user")}
                    </Button>
                </Can>
            ]}>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{t("username")}</TableCell>
                                    <TableCell>{t("email")}</TableCell>
                                    <TableCell>{t("roles")}</TableCell>
                                    <TableCell>{t("publisher")}</TableCell>
                                    <Can do="manage" on='User'>
                                        <TableCell>{t("actions")}</TableCell>
                                    </Can>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userStore.users && userStore.users
                                    .map((user: User, i: number) => (
                                        <TableRow key={user.username}>
                                            <TableCell>{i+1}</TableCell>
                                            <TableCell>{user.username}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.roles.map(role => role.name).join(", ")}</TableCell>
                                            <TableCell>{getPublisherName(user.publisherId)}</TableCell>
                                            <TableCell>
                                                <Can do="manage" on='User'>
                                                    <IconButton onClick={() => open("update-user", user)}>
                                                        <EditIcon color="primary" />
                                                    </IconButton>
                                                    <IconButton onClick={() => deleteUser(user)}>
                                                        <DeleteIcon color="error" />
                                                    </IconButton>
                                                </Can>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Container>
            <NewUserDialog isOpen={isOpen("new-user")} onClose={close} />
            <UpdateUserDialog user={selectedUser} isOpen={isOpen("update-user")} onClose={close} />
        </Panel >
    );
});