import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useStores } from '../../../stores';
import { Box, Collapse, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Publisher } from '../../../domain/Publisher';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

interface PublishersByGroup {
    [key: string]: Publisher[];
}

export default observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { publisherStore } = useStores();
    const [publishers, setPublishers] = React.useState<PublishersByGroup>();

    React.useEffect(() => {
        publisherStore.getInactivePublishers((data) => {
            const publByGroup = data.reduce((obj: any, curr: Publisher) => {
                if (curr.serviceGroup) {
                    const group = curr.serviceGroup.name;
                    const arr = obj[group];
                    if (arr) {
                        arr.push(curr);
                    } else {
                        obj[group] = [];
                        obj[group].push(curr);
                    }
                } else {
                    obj['---'].push(curr);
                }
                return obj;
            }, { '---': [] });
            setPublishers(publByGroup);
        });
    }, []);

    const goToPublisher = (publisher: Publisher) => {
        navigate(`/publishers/${publisher.id}`);
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <TableContainer>
                    <Table size="medium">
                        <TableBody>
                            {publishers && Object.keys(publishers)
                                .map((key: string) => (
                                    <TableRow key={key} >
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                            <Collapse in={true} timeout="auto" unmountOnExit>
                                                <Box sx={{ margin: 1 }}>
                                                    <Typography gutterBottom component="div">
                                                        <strong>{`${t("group")}: ${key}`}</strong>
                                                    </Typography>
                                                    <Table size="small">
                                                        <TableBody>
                                                            {publishers[key].map((publisher: Publisher) => (
                                                                <TableRow key={publisher.id} onClick={() => goToPublisher(publisher)}>
                                                                    <TableCell>{publisher.lastName}</TableCell>
                                                                    <TableCell>{publisher.firstName}</TableCell>
                                                                    <TableCell>{publisher.phoneNumber}</TableCell>
                                                                    <TableCell>{publisher.address}</TableCell>
                                                                    <TableCell>{publisher.notes}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Container>
    );
});