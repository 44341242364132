import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, TextareaAutosize, FormControlLabel, Checkbox } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { ScheduleWeek } from '../../../domain/ScheduleWeek';

interface Props {
    isOpen: boolean;
    week?: ScheduleWeek;
    onSubmit: (week: ScheduleWeek) => void;
    onClose: () => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        name: yup.string().required(t("field_is_required", { name: t("name") })),
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: props.week || {} as ScheduleWeek,
        onSubmit: (week) => {
            props.onSubmit(week);
            props.onClose();
        }
    });

    const { values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("update_week_schedule")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="name"
                        label={t("name")}
                        fullWidth
                        variant="standard"
                        value={values.name || ''}
                        onChange={formik.handleChange}
                    />

                    <div>
                        <FormControlLabel control={
                            <Checkbox
                                checked={values.skip}
                                onChange={(e, v) => {
                                    formik.setFieldValue('skip', v);
                                }}
                            />}
                            label={t("skip")} />
                    </div>

                    <TextareaAutosize
                        id="notes"
                        value={values.notes || ''}
                        minRows={6}
                        placeholder={t("notes")}
                        style={{ width: "100%" }}
                        onChange={formik.handleChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});