import Container from '@mui/material/Container';
import { useStores } from '../../stores';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useOpenDialog } from '../../hooks/useOpenDialog';
import UpdateUserDialog from '../users/UpdateUserDialog';
import ChangePasswordDialog from '../users/ChangePasswordDialog';

interface Props {
    index: number;
    tab: number;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { userStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();

    return (
        <div role="tabpanel" hidden={props.tab !== props.index} >
            <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {userStore.userData.username}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {userStore.userData.email || t("no_email")}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button color="info" onClick={() => openDialog("user-info")}>
                            {t("update_user_info")}
                        </Button>
                        <Button color="info" onClick={() => openDialog("change-password")}>
                            {t("change_password")}
                        </Button>
                    </CardActions>
                </Card>

                <UpdateUserDialog
                    user={userStore.userData}
                    isOpen={isOpen("user-info")}
                    onClose={closeDialog} />
                <ChangePasswordDialog
                    isOpen={isOpen("change-password")}
                    onClose={closeDialog}
                />
            </Container>
        </div>
    );
});