import { Box } from '@mui/material';

interface Props {
    text?: string;
}

export default (props: Props) => {

    return (
        <Box sx={{ p: 2, height: "200px", border: '1px dashed grey', textAlign: "center" }}>
            <h1>{props.text}</h1>
        </Box>
    );
};