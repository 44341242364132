import { GridToolbarContainer } from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";
import { FieldServiceReport } from '../../domain/FieldServiceReport';
import { Publisher, ServiceAssignment } from '../../domain/Publisher';
import MonthSwitcher from '../../components/MonthSwitcher';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface Props {
    date: Date,
    filter: string;
    reports: FieldServiceReport[],
    publishers: Publisher[],
    onFilterChange: (filter: string) => void;
    onDateUpdate: (date: any) => void;
}

export default (props: Props) => {
    const { t } = useTranslation();

    const submitted = props.reports && props.reports.length;
    const overall = props.publishers && props.publishers.length;

    const handleFilterChange = (event: SelectChangeEvent) => {
        props.onFilterChange(event.target.value as string);
    };

    return (
        <GridToolbarContainer >
            <MonthSwitcher date={props.date} onDateUpdate={props.onDateUpdate} />
            <FormControl sx={{ m: 1, minWidth: 140 }}>
                <InputLabel>{t("assignment")}</InputLabel>
                <Select
                    value={props.filter}
                    label={t("assignment")}
                    onChange={handleFilterChange}
                >
                    <MenuItem value="All">{t("all")}</MenuItem>
                    <MenuItem value="Publishers">{t("publishers")}</MenuItem>
                    <MenuItem value="All Pioneers">{t("all_pioneers")}</MenuItem>
                    {Object.values(ServiceAssignment).map(assign => {
                        return <MenuItem key={assign} value={assign}>{t(assign)}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <h2 style={{ marginLeft: "auto", marginRight: 10 }}>{t("submitted_reports", { submitted, overall })}</h2>
        </GridToolbarContainer>
    )
};