import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { Attendance } from '../../domain/Attendance';
import { isMobileOnly } from 'react-device-detect';

interface Props {
    attends: Attendance[];
    onBarClick: (attendId: number) => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();

    const chartData = props.attends.map(attend => {
        return {
            id: attend.id,
            name: moment(attend.date).format("DD.MM.YYYY"),
            count: attend.number
        }
    })

    const handleBarClick = (state: any) => {
        const attendId = state.activePayload![0].payload.id;
        props.onBarClick(attendId);
    }

    const interval = isMobileOnly ? 60 : 20;

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={chartData}
                margin={{ top: 5, bottom: 5 }} 
                onClick={handleBarClick} >
                <XAxis dataKey="name" interval={interval} />
                <YAxis />
                <Tooltip />
                <Bar type="monotone" dataKey="count" name={t("count")} fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    );
});