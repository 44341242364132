import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useStores } from '../../stores';
import { observer } from 'mobx-react-lite';
import S4ReportsTable from '../../components/s4reports/S4ReportsTable';
import { FieldServiceReport } from '../../domain/FieldServiceReport';
import PivotChartDialog from './PivotChartDialog';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import { IconButton } from '@mui/material';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { ServiceAssignment } from '../../domain/Publisher';

interface Props {
    serviceAssignment: ServiceAssignment | string;
}

export default observer((props: Props) => {
    const { reportStore } = useStores();
    const [reports, setReports] = React.useState<FieldServiceReport[]>([]);
    const { isOpen, openDialog, closeDialog } = useOpenDialog();

    React.useEffect(() => {
        reportStore.getPivotByAppointment(props.serviceAssignment, (data: FieldServiceReport[]) => {
            setReports(data);
        });
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <S4ReportsTable
                    reports={reports}
                    editable={false}
                    toolbarItems={[
                        <IconButton onClick={() => openDialog("chart-dialog")}>
                            <SsidChartIcon />
                        </IconButton>
                    ]}
                    onEdit={() => { }}
                    onDelete={() => { }}
                />
            </Paper>
            <PivotChartDialog
                isOpen={isOpen("chart-dialog")}
                reports={reports}
                onClose={closeDialog}
            />
        </Container>
    );
});