import * as React from 'react';
import Panel from '../panel/Panel';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import TerritoriesManagementTab from './management/TerritoriesManagementTab';
import Can from '../../components/Can';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useStores } from '../../stores';
import MobileTerritoriesManagementTab from './management/MobileTerritoriesManagementTab';


export default observer(() => {
    const { t } = useTranslation();
    const fileInput = React.useRef<HTMLInputElement>(null);
    const { territoryStore } = useStores();


    const uploadJson = (event: any) => {
        territoryStore.uploadJson(event.target.files);
    }

    const headerItems = () => {
        const items = [];
        if (!isMobile) {
            items.push(
                <Can key="upload_json" do="manage" on='Territory'>
                    <Button
                        color="inherit"
                        type="button"
                        onClick={() => fileInput.current && fileInput.current.click()}>
                        {t("upload_json")}
                        <input ref={fileInput} type="file" hidden onChange={uploadJson} />
                    </Button>
                </Can>
            );
        }

        return items;
    }

    return (
        <Panel title={t("territories")} headerItems={headerItems()}>
            {isMobileOnly ? <MobileTerritoriesManagementTab /> : <TerritoriesManagementTab />}
        </Panel >
    );
});