import * as React from 'react';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Button, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import NewPublisherDialog from './dialogs/NewPublisherDialog';
import { useTranslation } from "react-i18next";
import Can from '../../components/Can';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import PublishersTab from './publishers/PublishersTab';
import InactivePublishersTab from './inactive/InactivePublishersTab';
import DeletedPublishersTab from './deleted/DeletedPublishersTab';

export default observer(() => {
    const { t } = useTranslation();
    const { publisherStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [tab, setTab] = React.useState(0);

    React.useEffect(() => {
        if (!publisherStore.publishers.length) {
            publisherStore.getAllPublishers();
        }
    }, []);

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const headerItems = () => {
        return [
            <Can key="new_publisher_btn" do="create" on='Publisher'>
                <Button
                    type="button"
                    color="inherit"
                    onClick={() => openDialog("new-publisher")}>
                    {t("add_new_publisher")}
                </Button>
            </Can>
        ];
    }

    return (
        <Panel
            title={t("publishers")}
            headerItems={headerItems()}
        >
            <Tabs value={tab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                <Tab label={t("congregation")} />
                <Tab label={t("inactives")} />
                <Tab label={t("deactivated")} />
            </Tabs>
            <div role="tabpanel" hidden={tab !== 0}>
                <PublishersTab />
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <InactivePublishersTab />
            </div>
            <div role="tabpanel" hidden={tab !== 2}>
                <DeletedPublishersTab />
            </div>

            <NewPublisherDialog
                isOpen={isOpen("new-publisher")}
                onClose={closeDialog} />
        </Panel >
    );
});