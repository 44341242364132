import * as React from 'react';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Button, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useOpenDialog } from '../../hooks/useOpenDialog';
import Can from '../../components/Can';
import KnowledgeTopicDialog from './dialogs/KnowledgeTopicDialog';
import { KnowledgeTopic } from '../../domain/KnowledgeTopic';
import { useConfirm } from 'material-ui-confirm';
import KnowledgeNotesTab from './KnowledgeNotesTab';
import AppointmentsTab from './AppointmentsTab';

export default observer(() => {
    const { t } = useTranslation();
    const { knowledgeStore } = useStores();
    const confirm = useConfirm();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [selectedTopic, setSelectedTopic] = React.useState<KnowledgeTopic | undefined>();
    const [tab, setTab] = React.useState(0);

    React.useEffect(() => {
        knowledgeStore.getKnowledgeTopics();
    }, []);

    const deleteTopic = (id: number) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            closeDialog();
            setSelectedTopic(undefined);
            knowledgeStore.deleteKnowledgeTopic(id);
        });
    }

    const openCreateTopicDialog = () => {
        openDialog("manage-knowledge-topic")
    }

    const closeTopicDialog = () => {
        setSelectedTopic(undefined);
        closeDialog();
    }

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const headerItems = [];
    if (tab === 0) {
        headerItems.push(
            <Can key="manage_knowledge_topic_btn" do="manage" on='Knowledge'>
                <Button
                    type="button"
                    color="inherit"
                    onClick={openCreateTopicDialog}>
                    {t("create_knowledge_topic")}
                </Button>
            </Can>
        );
    }

    return (
        <Panel
            title={t("knowledge")}
            headerItems={headerItems}>

            <Tabs value={tab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                <Tab label={t("notes")} />
                <Tab label={t("appointments")} />
            </Tabs>
            <div role="tabpanel" hidden={tab !== 0}>
                <KnowledgeNotesTab />
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <AppointmentsTab />
            </div>

            <KnowledgeTopicDialog
                isOpen={isOpen("manage-knowledge-topic")}
                onClose={closeTopicDialog}
                onDelete={deleteTopic}
                topic={selectedTopic}
            />
        </Panel >
    );
});