import Panel from '../../panel/Panel';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import MyTerritoriesContent from './MyTerritoriesContent';

export default observer(() => {
    const { t } = useTranslation();
    return (
        <Panel title={t("my_territories")} headerItems={[]}>
            <MyTerritoriesContent size="lg" />
        </Panel >
    );
});