import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Autocomplete } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";
import { AccountingEntry } from '../../../domain/AccountingEntry';
import * as yup from 'yup';
import { getLocale } from '../../../utils/localeUtils';

interface Props {
    isOpen: boolean;
    entry?: AccountingEntry;
    date: Date;
    onSubmit: (entry: AccountingEntry) => void;
    onClose: () => void;
}

export default (props: Props) => {
    const { t } = useTranslation();

    const date = props.entry ? props.entry.date : props.date;
    let formData = {
        date: moment(date).format('YYYY-MM-DD'),
        side: "income",
        type: "cash",
    } as any;
    if (props.entry) {
        formData = { ...formData, ...props.entry };
    }

    const schema = yup.object().shape({
        date: yup.date().required(t("field_is_required", { name: t("date") })),
        document: yup.string().required(t("field_is_required", { name: t("document") })),
        counterparty: yup.string().required(t("field_is_required", { name: t("counterparty") })),
        description: yup.string().required(t("field_is_required", { name: t("description") })),
        code: yup.string().required(t("field_is_required", { name: t("code") })),
        amount: yup.number(),
        side: yup.string().required(t("field_is_required", { name: t("side") })),
        type: yup.string().required(t("field_is_required", { name: t("type") })),
        notes: yup.string().nullable(),
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: formData,
        onSubmit: (entry, { resetForm }) => {
            const updatedEntry = {
                ...entry,
                date: moment(entry.date).utcOffset(0, true).toDate(),
            } as AccountingEntry;
            if (!updatedEntry.amount) {
                updatedEntry.amount = 0;
            } else {
                updatedEntry.amount = +updatedEntry.amount;
            }

            props.onSubmit(updatedEntry);
            resetForm();
            props.onClose();
        }
    });

    const counterpartyOptions = process.env.REACT_APP_ACCOUNTING_COUNTERPARTY_OPTIONS!.split(",");
    const codeOptions = process.env.REACT_APP_ACCOUNTING_CODE_OPTIONS!.split(",");

    return (
        <Dialog fullWidth maxWidth="md" open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("accounting_entry")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("date")}
                            maxDate={new Date()}
                            value={formik.values.date}
                            onChange={value => {
                                formik.setFieldValue('date', value ? moment(value).format('YYYY-MM-DD') : value);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={!!formik.errors.date}
                                    helperText={formik.errors.date && formik.errors.date as string}
                                    margin="normal"
                                    name="date"
                                />}
                        />
                    </LocalizationProvider>
                    <Grid container>
                        <Grid item xs={6} padding={1}>
                            <TextField
                                margin="normal"
                                id="document"
                                label={t("document")}
                                fullWidth
                                variant="standard"
                                error={!!formik.errors.document}
                                helperText={formik.errors.document && formik.errors.document as string}
                                value={formik.values.document || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} padding={1}>
                            <Autocomplete
                                disablePortal={false}
                                id="counterparty"
                                freeSolo
                                selectOnFocus
                                options={counterpartyOptions}
                                value={formik.values.counterparty || ''}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        error={!!formik.errors.counterparty}
                                        helperText={formik.errors.counterparty && formik.errors.counterparty as string}
                                        margin="normal"
                                        label={t("counterparty")}
                                    />}
                                onInputChange={(e, counterparty: string) => {
                                    formik.setFieldValue("counterparty", counterparty)
                                }}
                                onChange={(e, counterparty) => {
                                    formik.setFieldValue("counterparty", counterparty)
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={8} padding={1}>
                            <TextField
                                margin="normal"
                                id="description"
                                label={t("description")}
                                fullWidth
                                variant="standard"
                                error={!!formik.errors.description}
                                helperText={formik.errors.description && formik.errors.description as string}
                                value={formik.values.description || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={4} padding={1}>
                            <Autocomplete
                                disablePortal={false}
                                id="code"
                                options={codeOptions}
                                value={formik.values.code || ''}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        error={!!formik.errors.code}
                                        helperText={formik.errors.code && formik.errors.code as string}
                                        margin="normal"
                                        label={t("code")}
                                    />}
                                onChange={(e, code) => {
                                    formik.setFieldValue("code", code)
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={4} padding={1}>
                            <TextField
                                margin="normal"
                                id="amount"
                                label={t("amount")}
                                type="number"
                                variant="standard"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                value={formik.values.amount || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={4} padding={1}>
                            <Autocomplete
                                disablePortal={false}
                                id="side"
                                options={["income", "expenses", "transfer"]}
                                getOptionLabel={(side: string) => t(side)}
                                value={formik.values.side}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        error={!!formik.errors.side}
                                        helperText={formik.errors.side && formik.errors.side as string}
                                        margin="normal"
                                        label={t("side")}
                                    />}
                                onChange={(e, side) => {
                                    formik.setFieldValue("side", side)
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} padding={1}>
                            <Autocomplete
                                disablePortal={false}
                                id="type"
                                options={["cash", "bank", "other"]}
                                getOptionLabel={(type: string) => t(type)}
                                value={formik.values.type}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        margin="normal"
                                        label={t("type")}
                                        error={!!formik.errors.type}
                                        helperText={formik.errors.type && formik.errors.type as string}
                                    />}
                                onChange={(e, type) => {
                                    formik.setFieldValue("type", type)
                                }}
                            />
                        </Grid>
                    </Grid>

                    {formik.values.side === "transfer" && (
                        <Autocomplete
                            disablePortal={false}
                            id="transferDirection"
                            options={["cash", "bank", "other"]}
                            getOptionLabel={(transferDirection: string) => t(transferDirection)}
                            value={formik.values.transferDirection}
                            renderInput={(params) => <TextField {...params} margin="normal" label={t("transfer_direction")} />}
                            onChange={(e, transferDirection) => {
                                formik.setFieldValue("transferDirection", transferDirection)
                            }}
                        />
                    )}

                    <TextField
                        margin="normal"
                        id="notes"
                        label={t("notes")}
                        type="text"
                        fullWidth
                        variant="standard"
                        error={!!formik.errors.notes}
                        helperText={formik.errors.notes && formik.errors.notes as string}
                        value={formik.values.notes || ''}
                        onChange={formik.handleChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};