import * as React from 'react';
import { Tab, Tabs } from '@mui/material';
import { useTranslation } from "react-i18next";
import { ScheduleEntry } from '../../domain/ScheduleEntry';
import { ScheduleWeek } from '../../domain/ScheduleWeek';
import ScheduleFieldServiceTab from './ScheduleFieldServiceTab';
import SchedulePageInfoBox from './SchedulePageInfoBox';

interface Props {
    week?: ScheduleWeek;
    entries: ScheduleEntry[];
    editable?: boolean;
    onEntryUpdate?: (entry: ScheduleEntry) => void;
    onEntryEdit?: (entry: ScheduleEntry) => void;
    onEntryHistory?: (entry: ScheduleEntry) => void;
}

export default (props: Props) => {
    const { t } = useTranslation();
    const [tab, setTab] = React.useState(0);

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <>
            <Tabs value={tab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                <Tab label={t("week")} />
                <Tab label={t("field_service_meetings")} />
            </Tabs>
            <div role="tabpanel" hidden={tab !== 0}>
                <SchedulePageInfoBox text={props.week && props.week.notes} />
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <ScheduleFieldServiceTab
                    week={props.week!}
                    entries={props.entries.filter(entry => entry.type === "field-service")}
                    editable={props.editable}
                    onEntryUpdate={props.onEntryUpdate}
                    onEntryEdit={props.onEntryEdit}
                    onEntryHistory={props.onEntryHistory}
                />
            </div>
        </>
    );
};