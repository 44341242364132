import {  IconButton, TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";
import Can from '../../../components/Can';
import { AccountingEntry } from '../../../domain/AccountingEntry';
import MonthSwitcher from '../../../components/MonthSwitcher';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { AccountingStartingPoint } from '../../../domain/AccountingStartingPoint';
import { border, format } from './accountingTableUtils';
import { useNavigate } from 'react-router-dom';

interface Props {
    entries: AccountingEntry[];
    startingPoint: AccountingStartingPoint;
    date: Date;
    onDateChange: (newDate: Date) => void;
}

export default (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <TableHead>
            <TableRow  >
                <TableCell sx={border} align="left" colSpan={5}>
                    <MonthSwitcher date={props.date} onDateUpdate={props.onDateChange} />
                </TableCell>
                <TableCell sx={border} style={{ backgroundColor: "#1565c021" }} align="center" colSpan={2}>
                    <h2>{format(props.startingPoint.cash)}</h2>
                </TableCell>
                <TableCell sx={border} style={{ backgroundColor: "#1565c021" }} align="center" colSpan={2}>
                    <h2>{format(props.startingPoint.bank)}</h2>
                </TableCell>
                <TableCell sx={border} style={{ backgroundColor: "#1565c021" }} align="center" colSpan={2}>
                    <h2>{format(props.startingPoint.cash + props.startingPoint.bank)}</h2>
                </TableCell>
                <Can do="manage" on='Accounting'>
                    <TableCell align="center" colSpan={1} style={{ backgroundColor: "#1565c021" }}>
                        <IconButton onClick={() => navigate('analysis')}>
                            <SsidChartIcon />
                        </IconButton>
                    </TableCell>
                </Can>
            </TableRow>
            <TableRow style={{ backgroundColor: "#2e7d3214" }}>
                <TableCell sx={border} align="center" colSpan={5}></TableCell>
                <TableCell sx={border} align="center" colSpan={2}><b>{t("cash")}</b></TableCell>
                <TableCell sx={border} align="center" colSpan={2}><b>{t("bank")}</b></TableCell>
                <TableCell sx={border} align="center" colSpan={2}><b>{t("total")}</b></TableCell>
                <Can do="manage" on='Accounting'>
                    <TableCell align="center" colSpan={1}></TableCell>
                </Can>
            </TableRow>
            <TableRow style={{ backgroundColor: "#2e7d3214" }}>
                <TableCell align="center"><b>{t("date")}</b></TableCell>
                <TableCell align="center"><b>{t("document")}</b></TableCell>
                <TableCell align="center"><b>{t("counterparty")}</b></TableCell>
                <TableCell align="center"><b>{t("description")}</b></TableCell>
                <TableCell sx={border} align="center"><b>{t("code")}</b></TableCell>
                <TableCell sx={border} align="center"><b>{t("in.")}</b></TableCell>
                <TableCell sx={border} align="center"><b>{t("exp.")}</b></TableCell>
                <TableCell sx={border} align="center"><b>{t("in.")}</b></TableCell>
                <TableCell sx={border} align="center"><b>{t("exp.")}</b></TableCell>
                <TableCell sx={border} align="center"><b>{t("in.")}</b></TableCell>
                <TableCell sx={border} align="center"><b>{t("exp.")}</b></TableCell>
                <Can do="manage" on='Accounting'>
                    <TableCell align="center"><b>{t("actions")}</b></TableCell>
                </Can>
            </TableRow>
        </TableHead>
    );
};