import * as React from 'react';
import Container from '@mui/material/Container';
import { Card, CardActions, CardContent, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

interface Props {
    index: number;
    tab: number;
}

export default (props: Props) => {
    const scheduleView = localStorage.getItem('settings_schedule_view');
    const [checked, setChecked] = React.useState<boolean>(scheduleView === 'fit');
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
        if (isChecked) {
            localStorage.setItem('settings_schedule_view', 'fit');
        } else {
            localStorage.setItem('settings_schedule_view', 'default');
        }
    };

    return (
        <div role="tabpanel" hidden={props.tab !== props.index} >
            <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {t("new_schedule_view")}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {t("only_for_mobile")}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <FormGroup>
                            <FormControlLabel
                                checked={checked}
                                control={<Switch onChange={handleChange} />}
                                label={t(checked ? "new" : "old")} />
                        </FormGroup>
                    </CardActions>
                </Card>
            </Container>
        </div>
    );
};