import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useTranslation } from "react-i18next";
import ScheduleAnalysis from '../../../domain/ScheduleAnalysis';

interface Props {
    analysis?: ScheduleAnalysis;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const [tab, setTab] = React.useState(0);

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <TableContainer>
                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                {props.analysis && (
                                    <>
                                        <TableCell align="center"><h3>{t("Student")}</h3></TableCell>
                                        <TableCell align="center"><h3>{t("Assistant")}</h3></TableCell>
                                        <TableCell align="center"><h3>{t("BibleReading")}</h3></TableCell>
                                        <TableCell align="center"><h3>{t("Conversation")}</h3></TableCell>
                                        <TableCell align="center"><h3>{t("BibleStudy")}</h3></TableCell>
                                        <TableCell align="center"><h3>{t("Talk")}</h3></TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {props.analysis && (
                                    <>
                                        <TableCell align="center"><h1>{props.analysis.school.summary.students}</h1></TableCell>
                                        <TableCell align="center"><h1>{props.analysis.school.summary.assistants}</h1></TableCell>
                                        <TableCell align="center"><h1>{props.analysis.school.summary.bibleReading}</h1></TableCell>
                                        <TableCell align="center"><h1>{props.analysis.school.summary.conversation}</h1></TableCell>
                                        <TableCell align="center"><h1>{props.analysis.school.summary.bibleStudy}</h1></TableCell>
                                        <TableCell align="center"><h1>{props.analysis.school.summary.talk}</h1></TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Tabs value={tab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                    {props.analysis && props.analysis.school.table.map(room => <Tab key={room.name} label={room.name} />)}
                </Tabs>
                {props.analysis && props.analysis.school.table.map((room, index) => (
                    <div key={index} role="tabpanel" hidden={tab !== index}>
                        <TableContainer>
                            <Table size="medium">
                                <TableHead>
                                    <TableRow>
                                            <TableCell>{t("surname")}</TableCell>
                                            <TableCell>{t("name")}</TableCell>
                                            <TableCell>{t("total")}</TableCell>
                                            <TableCell>{t("Conversation")}</TableCell>
                                            <TableCell>{t("BibleStudy")}</TableCell>
                                            <TableCell>{t("BibleReading")}</TableCell>
                                            <TableCell>{t("Talk")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {room.rows.map(row => (
                                        <TableRow key={row.id}>
                                            <TableCell>{row.lastName}</TableCell>
                                            <TableCell>{row.firstName}</TableCell>
                                            <TableCell>{row.total}</TableCell>
                                            <TableCell>{row.conversation}</TableCell>
                                            <TableCell>{row.bibleStudy}</TableCell>
                                            <TableCell>{row.bibleReading}</TableCell>
                                            <TableCell>{row.talk}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                ))}



            </Paper>
        </Container>
    );
});