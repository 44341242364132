import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';
import { CartWitnessingPlace } from '../domain/CartWitnessingPlace';

export class CartWitnessingStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    places: CartWitnessingPlace[];

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        this.places = [];
        makeAutoObservable(this);
    }

    createPlace = (place: CartWitnessingPlace, callback: () => void) => {
        this.serverStore.post('/cart-witnessing/place', place, action(() => {
            this.getAll();
            callback();
        }));
    }

    updatePlace = (id: number, place: CartWitnessingPlace, callback: () => void) => {
        this.serverStore.put(`/cart-witnessing/place/${id}`, place, action(() => {
            this.getAll();
            callback();
        }));
    }

    getAll = (callback?: () => void) => {
        this.serverStore.get('/cart-witnessing/place', action((places: CartWitnessingPlace[]) => {
            this.places = places;
            callback && callback();
        }));
    }

    deletePlace = (id: number) => {
        this.serverStore.delete(`/cart-witnessing/place/${id}`, action(() => {
            this.getAll();
        }));
    }

    createRecord = (data: any) => {
        this.serverStore.post('/cart-witnessing', data, action(() => {
            this.getAll();
        }));
    }

    updateRecord = (id: number, data: any) => {
        this.serverStore.put(`/cart-witnessing/${id}`, data, action(() => {
            this.getAll();
        }));
    }

    deleteRecord = (id: number) => {
        this.serverStore.delete(`/cart-witnessing/${id}`, action(() => {
            this.getAll();
        }));
    }
}