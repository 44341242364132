import * as React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from "react-i18next";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onFilterChange: (value: string) => void;
}

export default (props: Props) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState("all");

    const handleChange = (e: any, value: string) => {
        setValue(value);
    }

    const handleSubmit = () => {
        props.onFilterChange(value);
        props.onClose();
    }

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("filter")}</DialogTitle>
            <DialogContent>
                <Box>
                    <FormControl>
                        <RadioGroup value={value} defaultValue="all" onChange={handleChange}>
                            <FormControlLabel value="assigned-only" control={<Radio />} label={t("assigned_only")} />
                            <FormControlLabel value="unassigned-only" control={<Radio />} label={t("unassigned_only")} />
                            <FormControlLabel value="all" control={<Radio />} label={t("all")} />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{t("cancel")}</Button>
                <Button color="primary" onClick={handleSubmit}>{t("submit")}</Button>
            </DialogActions>
        </Dialog >
    );
};