import * as React from 'react';
import { Button, Checkbox, Container, Dialog, DialogActions, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import { LatLng } from 'leaflet';
import DefaultTextArea from '../../../components/TextArea';
import { useFormik } from 'formik';
import { Territory } from '../../../domain/Territory';
import { useConfirm } from 'material-ui-confirm';
import TerritoryMap from '../map/TerritoryMap';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { boundsId } from '../mapUtils';

interface Props {
    territory?: Territory;
    isOpen: boolean;
    onClose: () => void;
    onAssign?: (territory: Territory) => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { territoryStore } = useStores();
    const confirm = useConfirm();
    const startBoundary = props.territory ? JSON.parse(props.territory.boundary) as LatLng[][] : [];
    const [boundary, setBoundary] = React.useState<LatLng[][]>(startBoundary);

    const createShape = (bounds: LatLng[]) => {
        setBoundary(prevBoundary => [...prevBoundary, bounds]);
    }

    const updateShape = (bounds: LatLng[][]) => {
        setBoundary(bounds);
    }

    const closeDialog = () => {
        setBoundary([]);
        props.onClose();
    }

    let initialValues;
    if (props.territory) {
        if (props.territory) {
            const group = territoryStore.groups.find(group => group.id === props.territory!.territoryGroupId);
            initialValues = { ...props.territory, group, territoryGroupId: group?.id, upload: false };
        }
    } else {
        const group = territoryStore.groups[0];
        const territoryGroupId = group.id;
        initialValues = { title: '', notes: '', group, territoryGroupId, upload: false } as any;
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: (values, { resetForm }) => {
            const terr = {
                ...values,
                boundary: JSON.stringify(boundary)
            }
            delete terr.group;
            if (props.territory) {
                territoryStore.updateTerritory(props.territory.id, terr);
            } else {
                territoryStore.createTerritory(terr);
            }

            props.onClose();
            resetForm();
            setBoundary([]);
        },
    });

    const handleDelete = () => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            territoryStore.deleteTerritory(props.territory!.id, formik.values.upload);
            props.onClose();
        });
    }

    const handleAssign = () => {
        props.onClose();
        props.onAssign && props.onAssign(props.territory!);
    }

    const { values, errors } = formik;
    return (
        <Dialog open={props.isOpen} fullWidth={true} maxWidth="xl" onClose={closeDialog}>

            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={4} >
                        <DialogTitle>
                            {t("manage_territory")}
                            {(!!props.onAssign && props.territory) && (
                                <IconButton onClick={handleAssign}>
                                    <AssignmentIndIcon />
                                </IconButton>)}
                        </DialogTitle>
                        <FormControl sx={{ m: 1, minWidth: 140 }} fullWidth>
                            <InputLabel>{t("territory_group")}</InputLabel>
                            <Select
                                id='territoryGroupId'
                                value={values.group.id}
                                label={t("territory_group")}
                                onChange={(event: SelectChangeEvent) => {
                                    const groupId = Number(event.target.value);
                                    const group = territoryStore.groups.find(group => group.id === groupId)!;
                                    formik.setFieldValue('group', group);
                                    formik.setFieldValue('territoryGroupId', groupId);
                                }}
                            >
                                {territoryStore.groups.map(group => (
                                    <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                        <form onSubmit={formik.handleSubmit}>
                            <TextField
                                margin="normal"
                                id="title"
                                label={t("title")}
                                type="text"
                                fullWidth
                                variant="standard"
                                error={!!errors.title}
                                helperText={errors.title && errors.title as string}
                                value={values.title || ''}
                                onChange={formik.handleChange}
                            />
                            <DefaultTextArea
                                id="notes"
                                style={{ minHeight: 200 }}
                                value={values.notes || ''}
                                placeholder={t("notes")}
                                onChange={formik.handleChange}
                            />
                            <TextField
                                margin="normal"
                                id="boundary"
                                style={{ minHeight: 50, maxHeight: 150 }}
                                fullWidth
                                type="text"
                                variant="standard"
                                value={JSON.stringify(boundary)}
                                placeholder={t("boundary")}
                                onChange={(event) => {
                                    formik.handleChange(event);
                                    updateShape(JSON.parse(event.target.value) as LatLng[][])
                                }}
                            />
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="upload"
                                            checked={values.upload}
                                            onChange={(event) => {
                                                const result = event.target.checked;
                                                if (result) {
                                                    confirm({
                                                        title: t("are_you_sure"),
                                                        description: `${t("upload_to_territory_helper_long_text")}`,
                                                        confirmationText: t("yes"),
                                                        cancellationText: t("no")
                                                    }).then(() => {
                                                        formik.setFieldValue("upload", result);
                                                    });
                                                } else {
                                                    formik.setFieldValue("upload", result);
                                                }
                                            }}
                                        />
                                    }
                                    label={t("upload_to_territory_helper")}
                                />
                            </FormGroup>
                            <DialogActions>
                                <Button color="error" onClick={handleDelete}>{t("delete")}</Button>
                                <Button onClick={closeDialog}>{t("cancel")}</Button>
                                <Button color="primary" type="submit">{t("submit")}</Button>
                            </DialogActions>
                        </form>
                    </Grid>

                    <Grid item xs={8}>
                        <TerritoryMap
                            style={{ height: "100%", width: "100%" }}
                            polygons={boundary.map((arr: LatLng[]) => {
                                return {
                                    id: boundsId(arr, props.territory),
                                    bounds: arr,
                                    territory: props.territory!
                                }
                            })}
                            editing={true}
                            scrollWheelZoom={true}
                            currentTerritory={props.territory}
                            onPolygonCreate={createShape}
                            onPolygonEdit={updateShape}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Dialog >
    );
});